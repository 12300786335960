import React, { Component } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Logo from '../../assets/img/logo.png';

export default class Footer extends Component {
    state = {
        privacyPolicy: true
    }

    componentDidMount() {
        try {
            if (localStorage.getItem('privacyPolicy') !== null) {
                this.setState({ privacyPolicy: localStorage.getItem('privacyPolicy') });
            }
        } catch (error) {
            console.log(error);
        }
    }

    acceptPrivacyPolicy = () => {
        try {
            localStorage.setItem('privacyPolicy', false);
        } catch (error) {
            console.log(error);
        }
        this.setState({ privacyPolicy: false });
    }

    render() {
        return (
            <>
                <div className="footer bg-black">
                    <Container>
                        <Row className="py-md-5 py-0 text-center-mobile">
                            <Col xs={12} md={4} className="mb-md-0 mb-5 mt-md-0 mt-5">
                                <img src={Logo} className="img-fluid logo" alt="" />
                            </Col>
                            <Col xs={12} md={2} className="mb-md-0 mb-4">
                                <Row className="mb-md-0 mb-4">
                                    <Col xs={12}>
                                        <p className="text-white mb-md-4 mb-0">Produtos</p>
                                        <hr className="footer" />
                                    </Col>
                                </Row>
                                <a href="/produtos/pentest" className="text-decoration-none menu-footer">Pentest</a>
                                <a href="/produtos/blindagem" className="text-decoration-none menu-footer">Blindagem</a>
                                <a href="/produtos/waf" className="text-decoration-none menu-footer">WAF</a>
                                <a href="/produtos/ssl" className="text-decoration-none menu-footer">Certificado SSL</a>
                            </Col>
                            <Col xs={12} md={2} className="mb-md-0 mb-4">
                                <Row className="mb-md-0 mb-4">
                                    <Col xs={12}>
                                        <p className="text-white mb-md-4 mb-0">Institucional</p>
                                        <hr className="footer" />
                                    </Col>
                                </Row>
                                <a href="/sobre-nos" className="text-decoration-none menu-footer">Sobre nós</a>
                                <a href="https://americanas.gupy.io/" target="_blank" rel="noreferrer" className="text-decoration-none menu-footer">Carreiras</a>
                                <a href="/blog/posts" className="text-decoration-none menu-footer">Blog</a>
                                <a href="http://labs.siteblindado.com/" target="_blank" rel="noreferrer" className="text-decoration-none menu-footer">LABs</a>
                            </Col>
                            <Col xs={12} md={2} className="mb-md-0 mb-4">
                                <Row className="mb-md-0 mb-4">
                                    <Col xs={12}>
                                        <p className="text-white mb-md-4 mb-0">Consumidor</p>
                                        <hr className="footer" />
                                    </Col>
                                </Row>
                                <a href="/consumidor/verifique" className="text-decoration-none menu-footer">Verifique</a>
                                <a href="/consumidor/denuncie" className="text-decoration-none menu-footer">Denuncie</a>
                                <a href="/consumidor/politica-de-privacidade/" className="text-decoration-none menu-footer">Política de Privacidade</a>
                                <a href="/consumidor/contratos-de-servicos/" className="text-decoration-none menu-footer">Contratos de Serviços</a>
                                <a href="/consumidor/guia-de-seguranca/" className="text-decoration-none menu-footer">Guia de Segurança</a>
                                <a href="/consumidor/faq/" className="text-decoration-none menu-footer">FAQ | LGPD</a>
                                <a href="https://site-blindado.freshservice.com/support/home" target={"_blank"} rel="noreferrer" className="text-decoration-none menu-footer">FAQ | Site Blindado</a>
                            </Col>
                            <Col xs={12} md={2}>
                                <Row className="mb-md-0 mb-4">
                                    <Col xs={12}>
                                        <p className="text-white mb-md-4 mb-0">Canais de Atendimento</p>
                                        <hr className="footer" />
                                    </Col>
                                </Row>
                                <div className="menu-footer mb-4">
                                    <span className="text-white d-block">Site Blindado</span>
                                    <a href="tel:4003-2122" className="text-decoration-none">4003-2122</a>
                                </div>
                                <div className="menu-footer mb-4">
                                    <span className="text-white d-block">Comercial</span>
                                    <a href="mailto:comercial@siteblindado.com.br" className="text-decoration-none">comercial@siteblindado.com.br</a>
                                </div>
                                <div className="menu-footer mb-4">
                                    <span className="text-white d-block">Relacionamento</span>
                                    <a href="mailto:relacionamento@siteblindado.com.br" className="text-decoration-none">relacionamento@siteblindado.com.br</a>
                                </div>
                                <div className="menu-footer mb-4">
                                    <span className="text-white d-block">Suporte</span>
                                    <a href="mailto:suporte@siteblindado.com.br" className="text-decoration-none">suporte@siteblindado.com.br</a>
                                </div>
                                <div className="menu-footer mb-4">
                                    <span className="text-white d-block">Parcerias</span>
                                    <a href="mailto:parcerias@siteblindado.com.br" className="text-decoration-none">parcerias@siteblindado.com.br</a>
                                </div>
                                <div id="armored_website">
                                    <param id="aw_preload" value="true" />
                                    <param id="aw_use_cdn" value="true" />
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="py-5 align-items-center text-center-mobile">
                            <Col xs={12} md={6} className="mb-md-0 mb-4">
                                <span className="color-gray font-light">Site Blindado {new Date().getFullYear()} © Todos os direitos reservados.</span>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <div className="social-media">
                                            <a href="https://www.instagram.com/siteblindado/" target={"_blank"} rel="noreferrer">
                                                <FontAwesomeIcon icon={faInstagram} />
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="social-media">
                                            <a href="https://www.facebook.com/siteblindado" target={"_blank"} rel="noreferrer">
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="social-media">
                                            <a href="https://www.linkedin.com/company/site-blindado-sa" target={"_blank"} rel="noreferrer">
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="social-media">
                                            <a href="https://twitter.com/siteblindado" target={"_blank"} rel="noreferrer">
                                                <FontAwesomeIcon icon={faTwitter} />
                                            </a>
                                        </div>
                                    </Col>
                                    <Col className="d-flex justify-content-end">
                                        <div className="social-media">
                                            <a href="https://www.youtube.com/user/siteblindado" target={"_blank"} rel="noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {this.state.privacyPolicy === true &&
                    <div className="fixed-bottom card-lgpd">
                        <Card>
                            <Row>
                                <Col xs={12} md={9}>
                                    <small className="text-center"><strong>Site Blindado e os cookies</strong>: a gente usa cookies para melhorar a sua experiência no site. Ao continuar navegando, você concorda com a nossa <a href="/consumidor/politica-de-privacidade/" className="text-decoration-none" target="_blank"><strong className="color-primary">Política de Privacidade</strong></a>.</small>
                                </Col>
                                <Col xs={12} md={3} className="text-center">
                                    <Button onClick={this.acceptPrivacyPolicy} className="btn-green-dark">Continuar e fechar</Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                }
            </>
        )
    }
}
