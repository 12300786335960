import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Layout from "../../../components/Layout";
import Shield from '../../../assets/img/shield.png';
import ButtonSB from "../../../components/Button";

export default class GuiaSeguranca extends Component {

    componentDidMount() {
        document.title = "Site Blindado - Guia de Segurança";
    }

    render() {
        return (
            <Layout>
                <div className="padding-container padding-top-mobile bg-dark-green">
                    <Container>
                        <Row className="h-100 align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">Guia de Segurança Site Blindado</h1>
                            </Col>
                            <Col xs={12} md={4}>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={6} className="lock-bg">
                                <h3 className="color-dark text-center">O que a gente</h3>
                                <h1 className="color-primary font-bold text-center">NÃO FAZ</h1>
                            </Col>
                            <Col xs={12} md={6}>
                                <Row className="align-items-center mb-4">
                                    <Col md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col md={11}>
                                        <p className="m-0"><strong>Não</strong> solicitamos depósitos para terceiros;</p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col md={11}>
                                        <p className="m-0"><strong>Não</strong> entramos em contato por telefone para solicitar seu código SMS;</p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col md={11}>
                                        <p className="m-0"><strong>Não</strong> nos responsabilizamos pela venda de produtos ou prestação de serviços de nossos clientes, ou demais sites;</p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col md={11}>
                                        <p className="m-0"><strong>Não</strong> “removemos ou derrubamos” sites do ar;</p>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mb-4">
                                    <Col md={1}>
                                        <div className="bullet-point"><div /></div>
                                    </Col>
                                    <Col md={11}>
                                        <p className="m-0"><strong>Não</strong> verificamos se sites são falsos ou fraudulentos.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} className="d-flex align-items-center">
                                <img src={Shield} className="img-fluid shield" alt="" />
                                <h3 className="color-dark m-0">Como identificar um<br /><span className="font-semi-bold">selo Site Blindado falso?</span></h3>
                            </Col>
                            <Col xs={12} className="mt-5">
                                <Row>
                                    <Col xs={12} md={3} className="px-lg-5 mb-md-0 mb-4">
                                        <Card className="card-guia">
                                            <div className="diver" />
                                            <p>Nosso selo é reconhecido por ter uma <strong>data de auditoria</strong> atualizada diariamente.</p>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={3} className="px-lg-5 mb-md-0 mb-4">
                                        <Card className="card-guia">
                                            <div className="diver" />
                                            <p>Ao clicar no selo você será redirecionado para uma nova página que exibe a <strong>razão social e a url do site</strong>.</p>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={3} className="px-lg-5 mb-md-0 mb-4">
                                        <Card className="card-guia">
                                            <div className="diver" />
                                            <p>O tamanho da imagem é padrão, então se for exibido diferente do que aparece no nosso site, <strong>é falso</strong>.</p>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={3} className="px-lg-5 mb-md-0 mb-4">
                                        <Card className="card-guia">
                                            <div className="diver" />
                                            <p>Você também pode validar uma url em nosso site institucional na sessão “<strong>Verifique se um site é Blindado</strong>”.</p>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={12}>
                                <Card className="card-form-verifique">
                                    <Row className="h-100 align-items-center text-center-mobile">
                                        <Col xs={12} md={8}>
                                            <h4 className="text-medium text-center m-0">Verifique se seu site <strong>é blindado</strong>!</h4>
                                        </Col>
                                        <Col xs={12} md={4} className="mt-md-0 mt-4">
                                            <a href="/consumidor/verifique" rel="noreferrer"><ButtonSB type="black" btnName="verifique aqui" /></a>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={12}>
                                <h3 className="color-dark text-center mb-5">Como identificar se um<br /><strong>e-mail é falso e/ou fraudulento</strong>:</h3>
                            </Col>
                            <Col xs={12} md={4} className="px-5">
                                <div className="diver" />
                                <p>É sobre uma compra que você não fez.</p>
                            </Col>
                            <Col xs={12} md={4} className="px-5">
                                <div className="diver" />
                                <p>Verifique se seus dados são utilizados corretamente.</p>
                            </Col>
                            <Col xs={12} md={4} className="px-5">
                                <div className="diver" />
                                <p>Desconfie de e-mails que tenham links com caracteres, números e termos estranhos antes da primeira barra ou indica para um endereço que não seja o oficial.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container bg-dark-green">
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <h3 className="font-black mb-4 color-primary">COMO SABER SE UM SITE É CONFIÁVEL?</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <ul className="list-how-to-know">
                                    <li>Confira se o endereço possui <strong>https e se um cadeado é exibido ao lado do endereço</strong>;</li>
                                    <li>Confira se o endereço da página <strong>é o correto</strong>;</li>
                                    <li>Nunca clique diretamente em um link suspeito, <strong>clique com o botão direito do mouse e cole no navegador para verificar</strong>;</li>
                                    <li>Desconfie de <strong>ofertas muito atrativas</strong>;</li>
                                </ul>
                            </Col>
                            <Col xs={12} md={6}>
                                <ul className="list-how-to-know">
                                    <li><strong>Faça uma pesquisa sobre a empresa</strong> para obter mais informações antes de realizar uma compra;</li>
                                    <li>Verifique a reputação da empresa no site <strong>Reclame aqui</strong>;</li>
                                    <li>Pesquise em sites que avaliam os dados de registro do site como o registro.br, na url: <strong className="text-break">https:/registro.br/tecnologia/ferramentas/whois/</strong>;</li>
                                    <li>Dica: Se o site exibir <strong>nosso selo</strong> corretamente quer dizer que a empresa tem <strong>compromisso em identificar e corrigir graves vulnerabilidades de segurança</strong>.</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <div className="title-card"><h6 className="m-0">COMO CRIAR <strong>SENHAS FORTES</strong>:</h6></div>
                                <ul className="more-advices">
                                    <li>Ao criar uma senha, <strong>combine letras maiúsculas e minúsculas, símbolos e números</strong>. Essa atitude torna a ação de fraudadores mais difícil;</li>
                                    <li><strong>Não crie senhas com sequências óbvias</strong> como 123456, abcdef ou incluam letras que aparecem em sequência no teclado, como qwerty;</li>
                                    <li>Escolha senhas com no <strong>mínimo oito caracteres</strong>;</li>
                                    <li>Não utilize dados do <strong>seu endereço, aniversário, número de celular e outros dados relacionados a você</strong>;</li>
                                    <li>Ao trocar uma senha, é recomendável que a mesma seja <strong>diferente das utilizadas anteriormente</strong>.</li>
                                </ul>
                            </Col>
                            <Col xs={12} md={12} className="mt-5">
                                <div className="title-card"><h6 className="m-0">MAIS ALGUMAS <strong>DICAS IMPORTANTES</strong>:</h6></div>
                                <ul className="more-advices">
                                    <li>Proteja sua senha: se preferir anotá-las, <strong>guarde-as em um local seguro</strong>;</li>
                                    <li>Recebeu um alerta sobre atividades não realizadas por você? <strong>Troque a senha imediatamente</strong>;</li>
                                    <li>Mude sua senha periodicamente <strong>(Indicamos a cada 90 dias)</strong>;</li>
                                    <li>Evite usar a mesma senha em <strong>diversos sites</strong>;</li>
                                    <li><strong>Não compartilhe sua senha</strong>, ela é pessoal e intransferível;</li>
                                    <li><strong>Evite salvar o número de cartões</strong> em sites e aplicativos;</li>
                                    <li>Sempre que possível, <strong>utilize um cartão virtual para compras online</strong>;</li>
                                    <li>Não realize transações ou compartilhamento de dados sensíveis usando <strong>wifi-público</strong>;</li>
                                    <li>Caso identifique algum problema na venda de produtos ou prestação de serviços de nossos clientes, entre em contato com o canal oficial da respectiva loja ou empresa. Você também pode entrar em contato com os canais de defesa do consumidor como, por exemplo: <strong>Procon</strong>.</li>
                                </ul>
                            </Col>
                            <Col xs={12} md={12} className="mt-5">
                                <div className="title-card"><h6 className="m-0">DESCONFIOU QUE <strong>ALGUÉM ESTÁ SE PASSANDO POR NÓS?</strong></h6></div>
                                <ul className="more-advices">
                                    <li>Caso duvide da veracidade de perfis e propagandas em redes sociais, sites ou e-mails falsos, envie-nos por email: <strong>abuse@siteblindado.com</strong>;</li>
                                    <li>Esse canal é exclusivo para recepção de denúncias relacionadas ao uso indevido da marca ou serviços Site Blindado.</li>
                                </ul>
                            </Col>
                            <Col xs={12} md={12} className="mt-5">
                                <div className="title-card"><h6 className="m-0">COMO <strong>FALAMOS COM VOCÊ</strong>:</h6></div>
                                <div className="mb-4">
                                    <p className="font-bold">Nossos endereços são:</p>
                                    <p className="m-0">https://www.siteblindado.com/</p>
                                    <p className="m-0">https://app.siteblindado.com/login</p>
                                    <p className="m-0">https://blog.siteblindado.com/</p>
                                    <p className="m-0">http://labs.siteblindado.com/</p>
                                </div>
                                <div>
                                    <p className="font-bold">Nossos principais endereços de contato são:</p>
                                    <p className="m-0">suporte@siteblindado.com.br</p>
                                    <p className="m-0">relacionamento@siteblindado.com.br</p>
                                    <p className="m-0">contas.receberbit@b2wdigital.com</p>
                                    <p className="m-0">faturamento.bit@b2wdigital.com</p>
                                    <p className="m-0">Busque pelo domínio @siteblindado.com</p>
                                    <p className="m-0">Assuntos relacionados a atendimento são enviados através do endereço: @siteblindado.zendesk.com</p>
                                </div>
                            </Col>
                            <Col xs={12} md={12} className="mt-5">
                                <div className="title-card"><h6 className="m-0">BOLETOS BANCÁRIOS:</h6></div>
                                <ul className="more-advices">
                                    <li>Emissão através do banco: Itaú</li>
                                    <li>Beneficiário: BIT Services Inovação e Tecnologia</li>
                                    <li>Confira sempre se os dados bancários do beneficiário estão corretos</li>
                                    <li>Confira sempre se a nota fiscal tem as informações corretas sobre o produto</li>
                                    <li>Caso encontre alguma divergência, não pague e entre em contato conosco pelos canais de atendimento</li>
                                    <li>faturamento.bit@b2wdigital.com;</li>
                                    <li>relacionamento@siteblindado.com.br</li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}