import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../../components/Layout";
import ArrowButton from "../../../components/ArrowButton";

import Chat from "../../../assets/img/contact/chat-conversation.png";

export default class PaginaSucesso extends Component {

    componentDidMount() {
        document.title = "Site Blindado - Página de Sucesso de Contato";
    }

    render() {
        return (
            <Layout>
                <div className="section-height-100-center products-sess1">
                    <Container>
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col xs={12} md={6}>
                                <h3 className="font-black mb-5">Seu contato foi enviado com sucesso.</h3>
                                <h6 className="mb-5"><strong>Ficamos felizes pelo seu interesse!</strong><br /> Logo, um de nossos especialistas entrará em contato para começarmos a construir a segurança da sua aplicação web, com tudo que você precisa!</h6>
                                <ArrowButton link="/" type="green" side="left" text="Voltar para home" />
                            </Col>
                            <Col xs={12} md={6} className="text-right">
                                <img src={Chat} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}