import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";

export default class Obrigado extends Component {
    state = {
        data: [],
        orderId: ""
    }

    componentDidMount () {
        document.title = "Site Blindado - Obrigado";
        const orderId = localStorage.getItem("product_order");
        this.setState({ orderId: orderId });
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        return (
            <Layout>
                <div className={`padding-container bg-dark-green`}>
                    <Container>
                        <Row className="padding-top-mobile" style={{ paddingTop: "6rem", paddingBottom: "6rem" }}>
                            <Col xs={12} md={12}>
                                <h2 className="font-black text-center mb-xxl-5">Obrigado por escolher a Site Blindado!</h2>
                                <Row className="mb-4">
                                    <h6 className="text-center">Agradecemos sinceramente por adquirir um certificado SSL para o seu site. Sua decisão demonstra seu compromisso com a segurança e confiabilidade online. Com o SSL, você está garantindo a proteção dos dados dos seus clientes e construindo uma relação de confiança.</h6>
                                </Row>
                                <h6 className="text-center">Além disso, para acompanhar o andamento do seu pedido <strong>#{this.state.orderId}</strong>, um e-mail será enviado para você em breve, fornecendo todas as informações necessárias. Mais uma vez, obrigado por escolher a segurança conosco!</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}
