export const PRODUCTS = [
  {
    id: "5487a85b-5295-405f-a5ab-b4b60493b17b",
    name: "SSL Blindado",
    label: "Chaves com tamanho a partir de 2048 bits.",
    description: "Quando utilizado em um servidor web possibilita criptografar e proteger as informações transmitidas pela internet, garantindo uma conexão segura entre o navegador do usuário e o servidor web. Esse certificado faz a criptografia de apenas um domínio com e sem o “www.”. Quando instalado corretamente, o cadeado ao lado da barra de endereço fica visível na página.",
    validTime: "Créditos com duração de 1 ano",
    price: 500.00,
    fqdn: false
  },
  {
    id: "1122a85b-5295-405f-a5ab-a4b60493b34f",
    name: "SSL Blindado MDC",
    label: "Chaves com tamanho a partir de 2048 bits.",
    description: "Assegura a proteção de múltiplos endereços através da inserção de diferentes domínios em único certificado. É recomendado para empresas que possuam várias aplicações e servidores rodando com domínios diferentes e queiram facilitar o gerenciamento dos certificados. Através dele é possível validar mais de um domínio no mesmo certificado. É muito utilizado no meio corporativo onde, em geral, as infraestruturas são maiores.",
    validTime: "Créditos com duração de 1 ano",
    price: 450.00,
    fqdn: true,
    fqdnPrice: 270.00
  },
  {
    id: "7487a85b-5295-405f-a5ab-c4b60493b17c",
    name: "SSL Blindado Wildcard",
    label: "Chaves com tamanho a partir de 2048 bits.",
    description: "Esse certificado faz a criptografia de todos os subdomínios de 1º nível, além do domínio principal. Exemplo: siteblindado.com; portal2.siteblindado.com; labs.siteblindado.com. É indicado para quem tem o objetivo de simplificar a configuração do servidor web. Quando habilitado, aparece o mesmo cadeado do SSL Simples na barra de endereços.",
    validTime: "Créditos com duração de 1 ano",
    price: 1600.00,
    fqdn: false
  },
  {
    id: "9487a85b-5295-405f-a5ab-d4b60493b17d",
    name: "SSL Blindado SAN (UCC)",
    label: "Chaves com tamanho a partir de 2048 bits.",
    description: "Certificados SSL SAN (Subject Alternative Name) assegura a proteção de multíplos endereços através da inserção de domínios alternativos em único certificado. Também é conhecido como UCC SSL (Unified Communications SSL). Os certificados UCC são inteiramente compatíveis com o Microsoft Exchange, Office Communication, Lync Server.",
    validTime: "Créditos com duração de 1 ano",
    price: 450.00,
    fqdn: true,
    fqdnPrice: 270.00
  },
  {
    id: "4288bc33-fa25-4506-a974-536ada923044",
    name: "SSL TrustSign",
    label: "Chaves com tamanho a partir de 2048 bits.",
    description: "Quando utilizado em um servidor web possibilita criptografar e proteger as informações transmitidas pela internet, garantindo uma conexão segura entre o navegador do usuário e o servidor web. Esse certificado faz a criptografia de apenas um domínio com e sem o “www.”. Quando instalado corretamente, o cadeado ao lado da barra de endereço fica visível na página.",
    validTime: "Créditos com duração de 1 ano",
    price: 400.00,
    fqdn: false
  },
]

// const FUTURE_PRODUCTS = [
//   {
//     id: "1122a85b-5295-405f-a5ab-b4b60493f546",
//     name: "SSL Blindado EV",
//     label: "Ideal para Empresas e Pessoas Jurídicas",
//     description: "Quando utilizado em um servidor web possibilita criptografar e proteger as informações transmitidas pela internet, garantindo uma conexão segura entre o navegador do usuário e o servidor web.",
//     validTime: "Créditos com duração de 1 ano",
//     price: 399,
//     fqdn: false
//   },
//   {
//     id: "3433a85b-5295-405f-a5ab-a4b60493b17a",
//     name: "SSL Blindado MDC EV",
//     label: "Ideal para Empresas e Pessoas Jurídicas",
//     description: "Quando utilizado em um servidor web possibilita criptografar e proteger as informações transmitidas pela internet, garantindo uma conexão segura entre o navegador do usuário e o servidor web.",
//     validTime: "Créditos com duração de 1 ano",
//     price: 399,
//     fqdn: true
//   },
// ]
