import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import FormSB from "../../../components/Form";
import Layout from "../../../components/Layout";
import Slider from 'react-slick';

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Pentest01 from "../../../assets/img/products/pentest/pentest_01.png";

import CardImage01 from "../../../assets/img/products/pentest/penCardInfo_01.png";
import CardImage02 from "../../../assets/img/products/pentest/penCardInfo_02.png";
import CardImage03 from "../../../assets/img/products/pentest/penCardInfo_03.png";
import CardImage04 from "../../../assets/img/products/pentest/penCardInfo_04.png";
import CardImage05 from "../../../assets/img/products/pentest/penCardInfo_05.png";

export default class Pentest extends Component {
    state = {
        data: [],
        description: 'Serviço de Pentest da Site Blindado é conduzido por especialistas certificados em segurança da informação que buscam possíveis vulnerabilidades no sistema. Conheça mais!'
    }

    componentDidMount () {
        document.title = "Pentest – Site Blindado";
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', this.state.description ? this.state.description : '');
        };
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        const { data } = this.state;

        const settingsCertificates = {
            arrows: false,
            dots: false,
            slidesToShow: 3,
            autoplay: true,
            autoplaySpeed: 1500,
            slidesToScroll: 1,
            infinite: true,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplaySpeed: 1200,
                    }
                },
            ]
        };

        const pentestInfoCards = [
            {
                image: CardImage01,
                cardTitle: "Testes abrangentes",
                cardDetail: "Nossa equipe de especialistas em segurança cibernética realiza uma análise minuciosa dos seus sistemas, identificando possíveis brechas e pontos fracos."
            },
            {
                image: CardImage02,
                cardTitle: "Relatórios detalhados",
                cardDetail: "Após cada teste, fornecemos relatórios completos com uma avaliação precisa das vulnerabilidades encontradas e recomendações claras para corrigi-las."
            },
            {
                image: CardImage03,
                cardTitle: "Proteção proativa",
                cardDetail: "Ao realizar testes regulares de invasão, você estará um passo à frente de possíveis ataques cibernéticos, garantindo a integridade dos seus sistemas e a confidencialidade dos seus dados."
            },
            {
                image: CardImage04,
                cardTitle: "Especialistas altamente qualificados",
                cardDetail: "Nossa equipe possui ampla experiência e certificações, além de conhecimento em segurança cibernética, garantindo que seu negócio esteja protegido por profissionais altamente capacitados."
            },
            {
                image: CardImage05,
                cardTitle: "Suporte contínuo",
                cardDetail: "Além dos testes de invasão, oferecemos suporte especializado para ajudá-lo a implementar as medidas de segurança recomendadas e manter a proteção do seu negócio em longo prazo."
            }
        ]

        return (
            <Layout>
                <div className="section-height-100-center products-sess1">
                    <Row className="h-100 justify-content-center align-items-center">
                        <Col xs={12} md={6} className="d-lg-block d-none">
                            <h1 className="title-products font-black title-outline-green text-right">PENTEST.</h1>
                            <h1 className="title-products font-black text-right">PENTEST.</h1>
                            <h1 className="title-products font-black title-outline-green text-right">PENTEST.</h1>
                        </Col>
                        <Col xs={12} md={6} className="description-product-sess1">
                            <h1 className="d-md-none d-block title-products font-black title-outline-green mt-5">PENTEST.</h1>
                            <h3 className="font-semi-bold mb-4">Uma análise profunda no seu site em busca de vulnerabilidades.</h3>
                            <h6>O <strong>Pentest</strong> é conduzido por especialistas certificados em segurança da informação que buscam e exploram as vulnerabilidades presentes no sistema. No final desse processo, sua equipe de TI contará com um relatório detalhado sobre as falhas encontradas e os procedimentos recomendados para a resolução, além de suporte personalizado para auxiliar na compreensão das informações e as correções.</h6>
                        </Col>
                    </Row>
                </div>

                <div className="padding-container">
                    <Container>
                        <Card className="bg-dark-green pentest-info d-none d-lg-block">
                            <h4 className="text-justify">
                                <strong>Só no primeiro trimestre de 2023 foram detectadas 43,3 bilhões de ameaças:</strong><br />
                                um aumento de 31% em relação ao mesmo período do ano passado.
                            </h4>
                        </Card>
                        <Row className="padding-row justify-content-center">
                            <Col xs={12} md={6}>
                                <img src={Pentest01} style={{ borderRadius: "25px" }} className="img-fluid" alt="" />
                            </Col>
                            <Col xs={12} md={5} style={{ marginTop: "32px" }}>
                                <h3 className="color-dark" style={{ marginBottom: "40px" }}>
                                    <strong>Conﬁra o que o Pentest pode fazer pela sua empresa!</strong>
                                </h3>
                                <p className="text-justify">
                                    Uma solução completa de testes de segurança que identifica e explora vulnerabilidades em seus sistemas e redes.
                                </p>
                                <p className="text-justify">
                                    Com uma abordagem profissional e especializada, nossa equipe de especialistas simula ataques reais para avaliar a resistência do seu ambiente digital, encontrar e reportar as vulnerabilidades de segurança.
                                </p>
                                <div style={{ marginTop: "40px" }}>
                                    <a href="#form">
                                        <Button className={"btn-green-dark"} style={{ color: "#000" }}><strong>Converse com nossos especialistas <FontAwesomeIcon icon={faArrowRight} /></strong></Button>
                                    </a>
                                </div>
                            </Col>
                        </Row>

                        <Row className="padding-row justify-content-center align-items-center">
                            <Col xs={12} md={12}>
                                <h3 className="color-dark">
                                    <strong>Por que escolher o Pentest?</strong>
                                </h3>
                            </Col>

                            {pentestInfoCards.map((cardInfo) => (
                                <Row key={cardInfo.title} style={{ paddingTop: "3rem" }} className="align-items-center">
                                    <Col xs={12} md={3}>
                                        <img src={cardInfo.image} style={{ borderRadius: "15px" }} className="img-fluid" alt="" />
                                    </Col>
                                    <Col xs={12} md={9}>
                                        <Card style={{ padding: "2rem" }}>
                                            <Card.Body>
                                                <Card.Title className="mb-4" style={{ color: "#000" }}><strong>{cardInfo.cardTitle}</strong></Card.Title>
                                                <Card.Text>
                                                    {cardInfo.cardDetail}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                            )}
                        </Row>

                        <Row className="padding-row justify-content-center align-items-center">
                            <Col xs={12} md={12}>
                                <Card className="bg-dark-green" style={{ padding: "4rem 14rem" }}>
                                    <h4 className="text-center">
                                        Não coloque em risco a segurança da sua empresa.<br />
                                        <strong>Invista no Pentest e tenha a tranquilidade de saber que seus sistemas estão protegidos.</strong>
                                    </h4>
                                    <div className="text-center" style={{ marginTop: "40px" }}>
                                        <a href="#form">
                                            <Button className={"btn-green-dark"} style={{ color: "#000" }}><strong>Converse com nossos especialistas <FontAwesomeIcon icon={faArrowRight} /></strong></Button>
                                        </a>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="padding-row justify-content-center align-items-center">
                            <Col xs={12} md={12}>
                                <h3 className="color-dark text-center">Nossa equipe possui algumas das <br /><strong>principais certificações do mercado:</strong></h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12}>
                                <Slider {...settingsCertificates} className="p-0">
                                    <h1 className="font-black color-primary text-center">OSCP</h1>
                                    <h1 className="font-black color-primary text-center">OSWP</h1>
                                    <h1 className="font-black color-primary text-center">CEH</h1>
                                    <h1 className="font-black color-primary text-center">EMAPT</h1>
                                    <h1 className="font-black color-primary text-center">ISO27000</h1>
                                </Slider>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={6} className="mb-md-0 mb-4">
                                <Card className="card-products">
                                    <h3 className="font-light color-dark mb-5">Pentest de aplicação WEB</h3>
                                    <h6 className="font-light color-dark mb-4">Tem como objetivo <span className="font-semi-bold">simular um ataque cibernético ao sistema web</span>, identificando e explorando brechas.</h6>
                                    <p className="mt-2">· Possibilidade de reteste conforme o escopo definido; <br />
                                        · Análise com ou sem conhecimento prévio do sistema; <br />
                                        · Auxílio aos times técnicos na identificação e mitigação de vulnerabilidades.
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="card-products">
                                    <h3 className="font-light color-dark mb-5">Pentest de Redes</h3>
                                    <h6 className="font-light color-dark mb-4">Promove uma análise da rede de computadores e equipamentos, identificando brechas prejudiciais à rede da companhia.</h6>
                                    <p className="mt-2">· Testes de invasão aos ativos de tecnologia; <br />
                                        · Exfiltração de dados e informações em ativos comprometidos; <br />
                                        · Possibilidade de movimentação lateral entre ativos comprometidos; <br />
                                        · Auxílio aos times técnicos na identificação e mitigação de vulnerabilidades.
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} md={6} className="mb-md-0 mb-4">
                                <Card className="card-products">
                                    <h3 className="font-light color-dark mb-5">Pentest de API</h3>
                                    <h6 className="font-light color-dark mb-4">Analisa a segurança das APIs de um site, proporcionando integrações mais seguras entre sistemas.</h6>
                                    <p className="mt-2">· Testes automatizados e manuais de endpoints; <br />
                                        · Baseado em documentação prévia disponibilizada pelo cliente; <br />
                                        · Passa pelos endpoints em busca de falhas de manipulação de dados e vulnerabilidades; <br />
                                        · Apresenta meios de mitigação auxiliando as equipes técnicas em suas aplicações.
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="card-products">
                                    <h3 className="font-light color-dark mb-5">Pentest Mobile Site Blindado</h3>
                                    <h6 className="font-light color-dark mb-4">Indicado para desenvolvedores, testa a segurança de aplicações de plataformas móveis.</h6>
                                    <p className="mt-2">· Realizado em apps nas plataformas Android e iOS; <br />
                                        · Indica falhas de manipulação de dados pelo app; <br />
                                        · Aponta trechos de código vulneráveis e apresenta meios de mitigação.
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div id="form" className={`padding-container bg-dark-green ${(data.status !== undefined ? (data.status === 'error' ? 'bg-dark-red' : '') || (data.status === 'ok' ? 'bg-dark-blue' : '') : '')}`}>
                    <Container>
                        <FormSB type="form-client" parentCallback={this.callbackFunction} source="[PRODUTO] Pentest" title="Quer saber mais sobre o serviço de  Pentest?" description={<>Preencha o formulário a seguir para que a nossa equipe de especialistas possa entrar em contato e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> que atenda às suas necessidades.</>} />
                    </Container>
                </div>
            </Layout >
        );
    }
}
