import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from 'react-bootstrap';

import Layout from '../../components/Layout';
import ArrowButton from '../../components/ArrowButton';
import Loading from '../../components/Loading';
import InstagramPosts from '../../components/InstagramPosts';
import ContentfulPagination from '../../components/ContenfulPagination';
import {
  getContentfulCategory,
  getContentfulHeroImg,
  getContentfulAuthor,
  getContentfulSearchedData
} from '../../services/blogService';
import { formatBody } from '../../services/utils';

function BlogSearch() {

  const [posts, setPosts] = useState([])
  const [pagination, setPagination] = useState({})
  const [load, setLoad] = useState(false)
  let { keyword } = useParams();


  const getPosts = useCallback(async (skip, page) => {
    setLoad(true)
    try {
      const searchKey = decodeKeyword(keyword)
      const [contentful] = await Promise.all([getContentfulSearchedData(skip, searchKey)])
      const items = contentful.items

      const requests = items.map(async item => {
        const categoryId = item.fields.category.sys.id
        const heroImgId = item.fields.heroImage.sys.id
        const authorId = item.fields.author.sys.id

        const [categoryRes, imgRes, authorRes] = await Promise.all([
          getContentfulCategory(categoryId),
          getContentfulHeroImg(heroImgId),
          getContentfulAuthor(authorId)
        ])

        const category = categoryRes.fields.name
        const img = 'https:' + imgRes.fields.file.url
        const author = authorRes.fields.name

        return {
          ...item,
          category,
          image: img,
          author
        }
      })

      const processedPosts = await Promise.all(requests)
      const totalPages = Math.ceil(contentful.total / contentful.limit)
      const paginate = {
        total: contentful.total,
        active: page,
        pages: totalPages,
      }
      setPagination(paginate)
      setPosts(processedPosts)
      setLoad(false)
    } catch (error) {
      console.log(error)
      setLoad(false)
    }
  }, [keyword])

  useEffect(() => {
    document.title = 'Site Blindado - Blog'
    getPosts(0, 1)
  }, [getPosts])

  const decodeKeyword = (key) => {
    const decodeKey = decodeURIComponent(key);
    return decodeKey;
  }

  return (
    <>
      <Layout>
        <div className='padding-container mobile-height-100 bg-dark-green'>
          <Container>
            <Row className='align-items-center'>
              <Col xs={12} md={8}>
                <h1 className='font-black mb-4'>
                  Blog <br /> Site Blindado<span className='color-secondary'>.</span>
                </h1>
              </Col>
            </Row>
          </Container>
        </div>

        <div className='padding-container'>

          <Container>
            <Row>
              <Col xs={12} md={9} className='pe-lg-5'>
                <h3 className="text-dark font-black text-center mb-5">Resultados para: {keyword}</h3>
                <Row>
                  {posts.length === 0 ? (<p className="text-center">Resultados não encontrados</p>) : (
                    posts.map((post, index) => (
                      <Col xs={12} md={6} key={index} className='mb-4'>
                        <Card className='card-blog-news'>
                          <Row>
                            <Col xs={12} md={12}>
                              <a href={`/blog/single-post/${post.fields.slug}`} target='_blank' rel='noreferrer'>
                                <img src={post.image} className='img-fluid img-stretch img-blog-card' alt='' />
                              </a>
                            </Col>
                          </Row>
                          <Row className='h-100'>
                            <Col xs={12} md={12} className='description-news'>
                              <Row className='h-100'>
                                <small className='color-gray'>@{post.author}</small>
                                <Col xs={6}>
                                  <p className='font-semi-bold text-left text-uppercase'>{post.category}</p>
                                </Col>
                                <Col xs={6}>
                                  <p className='font-light color-gray text-right'>
                                    {new Date(post.fields.date).toLocaleDateString('pt-BR', 'DD/MM/YYYY')}
                                  </p>
                                </Col>
                                <h5 className='font-semi-bold color-dark mb-5'>{post.fields.title}</h5>
                                <p className='font-medium mb-4'>
                                  {formatBody(post.fields.body)}
                                </p>
                                <div className='flex-end'>
                                  <ArrowButton
                                    link={`/blog/single-post/${post.fields.slug}`}
                                    newTab={true}
                                    type='black'
                                    side='left'
                                    text='LEIA MAIS'
                                  />
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))
                  )}

                </Row>
                <Row>
                  <Col xs={12} md={12} className='d-flex justify-content-center'>
                    <ContentfulPagination currentPage={pagination.active} totalPages={pagination.pages} visiblePages={5} onPageChange={getPosts} />
                  </Col>
                </Row>

              </Col>

              <Col xs={12} md={3} className='d-lg-block d-none'>
                <Row className='mb-5'>
                  <Col xs={12} md={12}>
                    <h5 className='font-semi-bold color-dark mb-3'>Site Blindado</h5>
                    <p className='font-light color-gray'>
                      A Site Blindado é líder em segurança para e-commerce na América Latina, oferecendo soluções em
                      SSL, Blindagem de Sites, WAF e Pentest. Nesse blog trazemos as principais dicas de como se
                      proteger ao navegar pela internet, além de como as empresas podem melhorar a segurança de seus
                      sites e informações.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <InstagramPosts type='sidebar' title='Instagram' />
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
      <Loading show={load} />
    </>
  )
}
export default BlogSearch
