import React, { Component } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/img/logo.png';
import ButtonSB from "../Button";

export default class NavBar extends Component {
    state = {
        scroll: false
    }

    componentDidMount () {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.pageYOffset > 40) {
            this.setState({ scroll: true })
        } else {
            this.setState({ scroll: false })
        }
    }

    render () {
        return (
            <Navbar collapseOnSelect className={`fixed-top navbar-expand-lg nav-menu ${this.state.scroll && 'bg-dark-transition'}`} expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img src={Logo} className="img-fluid logo" alt="" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav className="d-flex align-items-center">
                            <NavDropdown className="menu-dropdown" title="Produtos" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/produtos/pentest">Pentest</NavDropdown.Item>
                                <NavDropdown.Item href="/produtos/blindagem">Blindagem</NavDropdown.Item>
                                <NavDropdown.Item href="/produtos/waf">WAF</NavDropdown.Item>
                                <NavDropdown.Item href="/produtos/ssl">Certificado SSL</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/sobre-nos">Sobre nós</Nav.Link>
                            <NavDropdown className="menu-dropdown" title="Consumidor" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/consumidor/verifique">Verifique</NavDropdown.Item>
                                <NavDropdown.Item href="/consumidor/denuncie">Denuncie</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown className="menu-dropdown" title="Biblioteca" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/blog/posts">Blog</NavDropdown.Item>
                                <NavDropdown.Item href="/materiais-ricos">Materiais Ricos</NavDropdown.Item>
                                {/* <NavDropdown.Item href="#">Cases de Sucesso</NavDropdown.Item> */}
                            </NavDropdown>
                            <NavDropdown className="menu-dropdown highlighted" title="Contato" id="collasible-nav-dropdown">
                                <NavDropdown.Item href="/contato">Já sou cliente</NavDropdown.Item>
                                <NavDropdown.Item href="/quero-ser-cliente">Quero ser cliente</NavDropdown.Item>
                                <NavDropdown.Item href="https://americanas.gupy.io/" target="_blank">Trabalhe conosco</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="https://app.siteblindado.com/" className="m-0" target="_blank"><ButtonSB type="black" btnName={<><FontAwesomeIcon className="me-2" icon={faSignInAlt} /><span>login</span></>} /></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}
