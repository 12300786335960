import axios from 'axios';
import { URL_CONTENTFUL_ARTICLE_SLUG, URL_CONTENTFUL_ASSETS, URL_CONTENTFUL_ENTRIES } from '../constants/blog';

const CONTENTFUL_TOKEN = process.env.REACT_APP_CONTENTFUL_TOKEN;

export async function getContentfulCategory(categoryId) {
  const { data } = await axios.get(`${URL_CONTENTFUL_ENTRIES}${categoryId}`, {
    headers: { Authorization: `Bearer ${CONTENTFUL_TOKEN}` },
  });
  return data;
}

export async function getContentfulHeroImg(heroImgId) {
  const { data } = await axios.get(`${URL_CONTENTFUL_ASSETS}${heroImgId}`, {
    headers: { Authorization: `Bearer ${CONTENTFUL_TOKEN}` },
  });
  return data;
}

export async function getContentfulAuthor(authorId) {
  const { data } = await axios.get(`${URL_CONTENTFUL_ENTRIES}${authorId}`, {
    headers: { Authorization: `Bearer ${CONTENTFUL_TOKEN}` },
  });
  return data;
}

export async function getContentfulAuthorImage(thumbnailId) {
  const { data } = await axios.get(`${URL_CONTENTFUL_ASSETS}${thumbnailId}`, {
    headers: { Authorization: `Bearer ${CONTENTFUL_TOKEN}` },
  });
  return data;
}

export async function getContentfulArticleBySlug(slug) {
  const { data } = await axios.get(`${URL_CONTENTFUL_ARTICLE_SLUG}${slug}`, {
    headers: { Authorization: `Bearer ${CONTENTFUL_TOKEN}` },
  });
  return data;
}

export async function getContentfulData(skip) {
  const { data } = await axios.get(`${URL_CONTENTFUL_ENTRIES}`, {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'article',
      limit: 4,
      skip: skip,
      order: '-fields.date',
      include: 1,
    },
  });
  return data;
}

export async function getContentfulSearchedData(skip, search) {
  console.log(search);
  const { data } = await axios.get(`${URL_CONTENTFUL_ENTRIES}`, {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'article',
      limit: 4,
      skip: skip,
      order: '-fields.date',
      include: 1,
      [`fields.body[match]`]: search,
    },
  });
  return data;
}
