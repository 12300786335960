import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default class ArrowButton extends Component {
    render() {
        return (
            <div className={((this.props.type === "green" && "arrow-button-green") || (this.props.type === "black" && "arrow-button-black") || (this.props.type === "white" && "arrow-button-white"))}>
                <a href={this.props.link} target={this.props.newTab ? '_blank' : '_self'} rel="noreferrer" className={"d-flex align-items-center " + ((this.props.side === "left" && "justify-content-start") || (this.props.side === "center" && "justify-content-center") || (this.props.side === "right" && "justify-content-end"))}><FontAwesomeIcon icon={faArrowRight} /><h6 className={`font-semi-bold mb-0`}>{this.props.text}</h6></a>
            </div >
        );
    }
}
