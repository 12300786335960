import React, { Component } from "react";
import NavBar from "../Navbar";
import Footer from "../Footer";
import { Toast, ToastContainer } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';



// Slick Carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function cwr (operation, payload) { }

export default class Layout extends Component {

    state = {
        alertToast: true,
        show: true
    }

    componentDidMount () {
        cwr("recordPageView", window.location.pathname + window.location.search);
        if (window.top !== window.self) {
            window.top.location.href = document.location.href;
        }

        try {
            if (localStorage.getItem('alertToast') !== null) {
                this.setState({ alertToast: localStorage.getItem('alertToast') });
            }
        } catch (error) {
            console.log(error);
        }
    }

    acceptAlertToast = () => {
        this.setState({ show: false });
        try {
            localStorage.setItem('alertToast', false);
        } catch (error) {
            console.log(error);
        }
        this.setState({ alertToast: false });
    }

    render () {
        return (
            <div>
                <NavBar />
                <div className="w-100 mx-auto overflow-auto">
                    {this.state.alertToast === true &&
                        <ToastContainer
                            className="p-3"
                            position={'top-end'}
                            style={{ zIndex: 2, marginRight: '50px', marginTop: '100px' }}
                        >
                            <Toast onClose={this.acceptAlertToast} show={this.state.show}>
                                <Toast.Header>
                                    <span style={{ marginRight: '5px' }}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />
                                    </span>
                                    <strong className="me-auto"> Atenção</strong>
                                </Toast.Header>
                                <Toast.Body>
                                    <p className="text-justify">
                                        A Site Blindado é uma empresa especializada em serviços de cibersegurança e não comercializa quaisquer produtos físicos. <a onClick={this.acceptAlertToast} href="/consumidor/denuncie">Saiba mais...</a>
                                    </p>
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>}

                    {this.props.children}
                </div>
                <Footer />
            </div >
        )
    }
}
