import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";
import FormSB from "../../components/Form";

export default class QueroSerCliente extends Component {

    state = {
        partner: "siteblindado"
    }

    componentDidMount () {
        document.title = "Site Blindado - Quero Ser Cliente";
        const pathname = document.location.pathname;
        const pathSplit = pathname.split("-");
        if (pathSplit.length > 3) {
            this.setState({ partner: pathSplit[3].toUpperCase() })
        }
    }

    render () {
        return (
            <Layout>
                <div className={`padding-container padding-top-mobile bg-${this.state.partner.toLowerCase()}`}>
                    <Container>
                        <Row className="h-100 align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">Quero ser cliente</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={12}>
                                {this.state.partner === "siteblindado" ? (
                                    <FormSB color="white" type="form-contact" title="Entre em Contato" description={<>Preencha o formulário a seguir para fazer parte da nossa equipe e juntos sermos a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                ) : (
                                    <FormSB color="white" type="form-client" partner={this.state.partner} title="Entre em Contato" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                )}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}
