import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import FormSB from "../../../components/Form";
import Layout from "../../../components/Layout";
import WAF from "../../../assets/img/products/waf.jpg";

export default class Pentest extends Component {
    state = {
        data: [],
        description: 'O WAF da Site Blindado oferece proteção ativa contra as principais ameaças encontradas na rede, inclusive as listadas no OWASP Top-10.  Também traz proteção anti-DDoS, API e proteção contra badbots. Conheça!'
    }

    componentDidMount() {
        document.title = "WAF – Site Blindado";
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', this.state.description ? this.state.description : '');
        };
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render() {
        const { data } = this.state;

        return (
            <Layout>
                <div className="section-height-100-center products-sess1">
                    <Row className="h-100 justify-content-center align-items-center">
                        <Col xs={12} md={6} className="d-lg-block d-none">
                            <h1 className="title-products font-black title-outline-green text-right">WAF.WAF.</h1>
                            <h1 className="title-products font-black text-right">WAF.WAF.</h1>
                            <h1 className="title-products font-black title-outline-green text-right">WAF.WAF.</h1>
                        </Col>
                        <Col xs={12} md={6} className="description-product-sess1">
                            <h1 className="d-md-none d-block title-products font-black title-outline-green">WAF.WAF.</h1>
                            <h3 className="font-semi-bold mb-4">Garanta o máximo de segurança para o seu site.</h3>
                            <h6>Nosso Firewall de Aplicação Web oferece uma proteção ativa contra as principais ameaças encontradas, incluindo as listadas no OWASP Top-10.</h6>
                        </Col>
                    </Row>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row className="d-flex align-items-center">
                            <Col xs={12} md={6} className="text-center mb-md-0 mb-4">
                                <h2 className="text-left text-center-mobile color-primary font-black text-lg">NOSSAS <br />SOLUÇÕES</h2>
                                <img src={WAF} className="img-fluid d-lg-block d-none" alt="" style={{ maxHeight: 600 }} />
                            </Col>
                            <Col xs={12} md={6}>
                                <Row className="ms-md-4 ms-0">
                                    <Col xs={12} md={12} className="mb-5">
                                        <h3 className="color-dark font-semi-bold mb-2">WAF Enterprise</h3>
                                        <div className="diver mb-3" />
                                        <h6 className="color-dark font-light">Realizado em parceria com a <span className="font-semi-bold">Imperva</span>, considerada a melhor solução em WAF do mercado segundo a consultoria Gartner. Oferece diversas possibilidades de personalização, além de possuir suporte 24x7.</h6>
                                    </Col>
                                    <Col xs={12} md={12} className="mb-5">
                                        <h3 className="color-dark font-semi-bold mb-2">WAF PRO </h3>
                                        <div className="diver mb-3" />
                                        <h6 className="color-dark font-light">Em parceria com a <span className="font-semi-bold">Azion</span>, pode ser personalizado para atender demandas específicas e é recomendado para URLs e APIs de forma ilimitada.</h6>
                                    </Col>
                                    <Col xs={12} md={12} className="mb-5">
                                        <h3 className="color-dark font-semi-bold mb-2">WAF Starter</h3>
                                        <div className="diver mb-2" />
                                        <h6 className="color-dark font-light">Com acompanhamento de instalação e configuração, suporta tráfego até 1TB, conta com CDN Nacional e sistema em parceria com a <span className="font-semi-bold">GoCache</span>.</h6>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <h4 className="color-dark font-light">Todos os nossos planos de WAF tem suporte personalizado para ajudar na instalação, configuração e monitoramento.</h4>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={`padding-container ${(data.status !== undefined ? ((data.status === 'error' ? 'bg-dark-red' : '') || (data.status === 'ok' ? 'bg-dark-blue' : '')) : '')}`}>
                    <Container>
                        <FormSB color="white" type="form-client" parentCallback={this.callbackFunction} source="[PRODUTO] WAF" title="Quer saber mais sobre WAF?" description={<>Preencha o formulário a seguir para que a nossa equipe de especialistas possa entrar em contato e oferecer a  <span className="font-semi-bold color-primary">melhor solução de segurança online</span> que atenda às suas necessidades.</>} />
                    </Container>
                </div>
            </Layout>
        );
    }
}