import React, { Component } from "react";
import { Button } from "react-bootstrap";

export default class ButtonSB extends Component {
    render () {
        return (
            <>
                <Button onClick={this.props.onClick} className={(this.props.type === "green-dark" && "btn-green-dark") || (this.props.type === "green" && "btn-green") || (this.props.type === "black" && "btn-black")}>{this.props.btnName}</Button>
            </>
        );
    }
}
