import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Layout from "../../../components/Layout";
import FormSB from "../../../components/Form";
import BlogNews from "../../../components/BlogNews";

export default class ContratosServicos extends Component {
    state = {
        data: [],
    }

    componentDidMount () {
        document.title = "Site Blindado - Contras de Serviços";
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        return (
            <Layout>
                <div className="padding-container padding-top-mobile bg-dark-green">
                    <Container>
                        <Row className="h-100 align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">Contratos de Serviços</h1>
                            </Col>
                            <Col xs={12} md={4}>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/MEMORIALDESCRITIVOBLINDAGEMDESITES.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h6 className="font-light text-center color-dark">Memorial Descritivo Blindagem de Sites</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/MEMORIALDESCRITIVOCERTIFICADOSDIGITAISSSLBLINDADO.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h6 className="font-light text-center color-dark">Memorial Descritivo Certificados Digitais SSL Blindado</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/TERMODEUSODOSOFTWAREPORTALSITEBLINDADO.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h6 className="font-light text-center color-dark">Termo de Uso de Software Portal Site Blindado</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/CONTRATOPADRAODECESSAODEUSODESOFTWAREVIAINTERNET_20241119.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h6 className="font-light text-center color-dark">Contrato Padrão de Cessão de Uso de Software Via Internet Versão 2</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/CONTRATOPADRAODECESSAODEUSODESOFTWAREVIAINTERNET.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h6 className="font-light text-center color-dark">Contrato Padrão de Cessão de Uso de Software Via Internet Versão 1</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                            <Col xs={12} md className="mb-md-0 mb-4">
                                <a className="text-decoration-none" href="/documents/ANEXOAOCONTRATODECESSAODEUSODESOFTWARELGPD.pdf" download>
                                    <Card className="card-download-contract">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <FontAwesomeIcon icon={faDownload} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <h6 className="font-light text-center color-dark">LGPD</h6>
                                            </Col>
                                        </Row>
                                    </Card>
                                </a>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <BlogNews />
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={12}>
                                <FormSB color="white" type="form-client" source="Página de Contratos e Serviços" parentCallback={this.callbackFunction} title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout >
        );
    }
}
