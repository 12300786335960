import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Slider from 'react-slick';
import Layout from "../../../components/Layout";
import ArrowButton from "../../../components/ArrowButton";
import ButtonSB from "../../../components/Button";
import FormSB from "../../../components/Form";
import InstagramPosts from "../../../components/InstagramPosts";
import BlogNews from "../../../components/BlogNews";

// images
import Pentest from "../../../assets/img/file-computer.png";
import Blindagem from "../../../assets/img/shield-check.png";
import WAF from "../../../assets/img/wall-fire.png";
import SSL from "../../../assets/img/padlock.png";
import HomeGraph from "../../../assets/img/home/home-graph.jpg";
import Testimonial from "../../../assets/img/home/testimonial.png";

export default class HomeB extends Component {
    state = {
        settingsTestimonial: {
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
        clientTestimonial: [
            { name: 'Felipe Escudeiro', testimonial: 'O comprometimento do time do Site Blindado nos proporciona tranquilidade e eficiência. Além de fornecer um serviço com qualidade, capaz de garantir a segurança do nosso site, a parceria aumenta a confiabilidade de nossos clientes e parceiros.', role: 'CTO KaBuM!' },
            { name: 'Ricardo Vieira', testimonial: 'A mesma tranquilidade que nossos clientes têm quando veem o selo da Site Blindado em nosso e-commerce, é compartilhada por nossa equipe quando se trata dos serviços da Site Blindado.', role: 'Diretor de E-commerce UltraFarma' },
            { name: 'Erica Mendes', testimonial: 'A Site Blindado atende todas as nossas expectativas. Possui uma equipe de suporte ágil, qualificada e comprometida, garantindo para nós e nossos clientes um serviço eficiente e seguro.', role: 'Coord. de E-commerce Fini Store' },
            { name: 'Carlos Martins', testimonial: 'Acreditamos na Site Blindado e temos em vocês um parceiro forte e que nos deixa ter “um sono tranquilo”, pois antes de vocês sofremos vários ataques e tivemos dificuldades de performance que hoje não temos mais', role: 'Projetos EDIÇÕES CNBB - Conferência Nacional dos Bispos do Brasil' }
        ],
        clientsItems: [
            { name: 'submarino', image: 'submarino.png' }, { name: 'renner', image: 'renner.png' }, { name: 'shoptime', image: 'shoptime.png' }, { name: 'ultrafarma', image: 'ultrafarma.png' },
            { name: 'kabum', image: 'kabum.png' }, { name: 'polishop', image: 'polishop.png' }, { name: 'deloitte', image: 'deloitte.png' }, { name: 'itau', image: 'itau.png' },
            { name: 'claro', image: 'claro.png' }, { name: 'localiza', image: 'localiza.png' }, { name: 'ipiranga', image: 'ipiranga.png' }, { name: 'americanas', image: 'americanas.png' }
        ],
        instagramPosts: [],
        data: []
    }

    componentDidMount() {
        document.title = "Site Blindado - Home (B)";
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render() {
        const { settingsTestimonial, clientTestimonial, clientsItems } = this.state;

        return (
            <Layout>
                <div className="section-height-100-center bg-dark-sb home-sess1">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <h1 className="font-black">Proteção de <br />dados para todos <br /> os momentos<span className="color-primary">.</span></h1>
                                <ArrowButton link="/2#products" type="green" side="left" text="Conheça as nossas soluções" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container" id="products">
                    <Container>
                        <Row>
                            <Col xs={12} md={6} className="mb-md-0 mb-5">
                                <h3 className="font-semi-bold text-dark mb-5">Por que ser <br /> <span className="color-gradient">Site Blindado</span>?</h3>
                                <h6 className="text-dark mb-5 pe-5">Quem é <span className="font-semi-bold">Site Blindado</span> conta com um universo de serviços que conversam entre si para oferecer respostas aos desafios do dia a dia.</h6>
                                <a href="/2#form" rel="noreferrer"><ButtonSB type="green-dark" btnName="FALE CONOSCO" /></a>
                            </Col>
                            <Col xs={12} md={6}>
                                <img src={HomeGraph} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={6} className="mb-md-0 mb-4">
                                <Card className="card-products">
                                    <Row className="mb-4">
                                        <Col xs={2}>
                                            <img src={Blindagem} className="img-fluid" alt="" style={{ maxHeight: 56 }} />
                                        </Col>
                                        <Col xs={10} className="d-flex align-items-center">
                                            <h3 className="color-dark font-semi-bold m-0">Blindagem de sites</h3>
                                        </Col>
                                    </Row>
                                    <h6 className="font-light color-dark mb-4">Uma <span className="font-semi-bold">análise de segurança</span> feita por scans automatizados, que encontram e listam vulnerabilidades que possam colocar um site em risco.</h6>
                                    <p className="mt-2">· Scans Site Blindado; <br />
                                        · Quick Pentest; <br />
                                        · Portal de Segurança; <br />
                                        · Relatórios de inspeção.
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="card-products">
                                    <Row className="mb-4">
                                        <Col xs={2}>
                                            <img src={Pentest} className="img-fluid" alt="" style={{ maxHeight: 56 }} />
                                        </Col>
                                        <Col xs={10} className="d-flex align-items-center">
                                            <h3 className="color-dark font-semi-bold m-0">Pentest</h3>
                                        </Col>
                                    </Row>
                                    <h6 className="font-light color-dark mb-4">Realizado manualmente por um analista de segurança da Site Blindado, o teste de invasão <span className="font-semi-bold">identifica e explora vulnerabilidades</span> que só são percebidas através da lógica humana.</h6>
                                    <p className="mt-2">· Blackbox e Greybox; <br />
                                        · Analistas certificados; <br />
                                        · Mobile com code revew; <br />
                                        · Relatórios técnicos e gerenciais.
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} md={6} className="mb-md-0 mb-4">
                                <Card className="card-products">
                                    <Row className="mb-4">
                                        <Col xs={2}>
                                            <img src={SSL} className="img-fluid" alt="" style={{ maxHeight: 56 }} />
                                        </Col>
                                        <Col xs={10} className="d-flex align-items-center">
                                            <h3 className="color-dark font-semi-bold m-0">Certificados SSL</h3>
                                        </Col>
                                    </Row>
                                    <h6 className="font-light color-dark mb-4">Protegem informações que transitam entre a aplicação WEB e o servidor através de criptografia,
                                        <span className="font-semi-bold">fundamental para todo e qualquer site que solicita dados sensíveis</span>,
                                        como e-mail, telefone, cartão de crédito, CPF e senhas.
                                    </h6>
                                    <p className="mt-2">· Portal de segurança; <br />
                                        · Suporte em português; <br />
                                        · Scan SSL; <br />
                                        · Configuração e instalação em múltiplos sevidores sem custo adicional.
                                    </p>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card className="card-products">
                                    <Row className="mb-4">
                                        <Col xs={2}>
                                            <img src={WAF} className="img-fluid" alt="" style={{ maxHeight: 56 }} />
                                        </Col>
                                        <Col xs={10} className="d-flex align-items-center">
                                            <h3 className="color-dark font-semi-bold m-0">WAF</h3>
                                        </Col>
                                    </Row>
                                    <h6 className="font-light color-dark mb-4">Esse serviço de segurança oferece uma
                                        <span className="font-semi-bold">proteção ativa contra ataques manuais ou automatizados</span>,
                                        funcionando como uma barreira de segurança contra invasores.
                                    </h6>
                                    <p className="mt-2">· Suporte para implementação de CDN; <br />
                                        · Painel de segurança; <br />
                                        · Alta disponibilidade; <br />
                                        · Proteção contra ataques DDoS.
                                    </p>
                                </Card>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <InstagramPosts type='container' title={<>Explore o universo <span className="font-semi-bold">Site Blindado</span></>} />
                        </Row>
                        <Row className="padding-row justify-content-center">
                            <Col xs={12}>
                                <h1 className="color-primary font-bold text-center">Orgulho em proteger</h1>
                            </Col>
                            <Col xs={12}>
                                <Row className="mt-5">
                                    {clientsItems.map((client, index) => (
                                        <Col xs={6} md={2} className="d-flex align-items-center justify-content-center" key={index}>
                                            <img src={require(`../../../assets/img/home/${client.image}`)} className="img-fluid" alt="" />
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding-row mb-md-0 mb-5">
                            <Col xs={12} md={6} className="d-flex justify-content-center align-items-center">
                                <img src={Testimonial} className="img-fluid" alt="" />
                            </Col>
                            <Col xs={12} md={6} className="d-flex flex-column justify-content-center">
                                <h3 className="color-dark mb-5">O que dizem <span className="color-primary font-semi-bold">sobre nós</span></h3>
                                <Slider {...settingsTestimonial} className="slick-testimonial-home">
                                    {clientTestimonial.map((testi, index) => (
                                        <div key={index}>
                                            <h6 className="color-gray mb-4">“{testi.testimonial}"</h6>
                                            <h6 className="color-primary font-semi-bold">{testi.name}</h6>
                                            <p>{testi.role}</p>
                                        </div>
                                    ))}
                                </Slider>
                            </Col>
                        </Row>
                        <Row className="padding-row" id="form">
                            <Col xs={12} md={12}>
                                <FormSB color="white" type="form-client" parentCallback={this.callbackFunction} source="Home, versão 2" title={<>Proteja a <br /> <span className="color-primary">sua empresa</span></>} description={<>Conte-nos um pouco mais sobre <span className="font-semi-bold">o seu negócio</span>. Entraremos em contato em breve.</>} />
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <BlogNews />
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}