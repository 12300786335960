import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";
import FormSB from "../../components/Form";

export default class Carreiras extends Component {

    componentDidMount() {
        document.title = "Site Blindado - Carreiras";
    }

    render() {

        return (
            <Layout>
                <div className="padding-container bg-dark-green">
                    <Container>
                        <Row className="h-100 align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">Carreiras</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={12}>
                                <FormSB color="white" type="form-client" title="Trabalhe Conosco" description={<>Preencha o formulário a seguir para fazer parte da nossa equipe e juntos sermos a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}