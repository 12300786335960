import React, { Component } from "react";
import { Container, Row, Col, } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faBuilding, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import Layout from "../../../components/Layout";
import FormSB from "../../../components/Form";
import ButtonSB from "../../../components/Button";
import BlogNews from "../../../components/BlogNews";
import Loading from "../../../components/Loading";

// images
import SeloImg from '../../../assets/img/consumer/selo-site-blindado.png';

export default class SeloTrustSign extends Component {
    state = {
        sealInformation: [],
        url: '',
        exceptions: ['sondadelivery.com.br'],
        load: false
    }

    componentDidMount () {
        this.getSealInformation();
        document.title = "Site Blindado - Selo SSL";
        var link = document.createElement('meta');
        link.setAttribute('name', 'robots');
        link.content = 'noindex';
        document.getElementsByTagName('head')[0].appendChild(link);
    }

    getSealInformation = async () => {
        this.setState({ load: true });
        let url = new URL(document.location).searchParams.get("hostname");
        let host = (document.referrer !== undefined && document.referrer !== "") ? document.referrer.match(/:\/\/(.[^/]+)/)[1] :
            url;
        await axios.get(`https://seal.siteblindado.com/v1/domains/${host}/seal_image?brand=trustsign`)
            .then(res => {
                this.setState({ sealInformation: res.data, load: false })
            }).catch(err => {
                this.setState({ load: false, url });
            });
    }

    render () {
        const { sealInformation } = this.state;

        return (
            <>
                <Layout>
                    {sealInformation.status_seal === '1' ?
                        <>
                            <div className="section-height-100-center bg-dark-green">
                                <Container>
                                    <Row>
                                        <h2 className="text-center">Sua conexão com o site <strong className="break-line">{sealInformation.device}</strong> está <strong>PROTEGIDA</strong>.</h2>
                                    </Row>
                                </Container>
                            </div>
                            <div className="bg-dark">
                                <Container>
                                    <Row className="py-4">
                                        <Col xs={12} className="d-flex justify-content-center align-items-center">
                                            <h3 className="mb-0"><strong className="color-primary">TRUSTSIGN</strong> É UMA MARCA SITE BLINDADO!</h3>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                            <div className="padding-container">
                                <Container>
                                    <Row>
                                        <Col xs={12} md={6} className="mb-5 mb-md-0">
                                            <h3 className="color-dark text-center mb-5">Detalhes do Certificado</h3>
                                            <Row className="align-items-center mb-4">
                                                <Col md={1} className="d-none d-lg-block">
                                                    <div className="icon-seal"><FontAwesomeIcon icon={faAward} /></div>
                                                </Col>
                                                <Col sm={12} md={11}>
                                                    <h6 className="color-dark m-0"><strong>Site Certificado</strong> <br /><span className="color-gray">{sealInformation.device}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-4">
                                                <Col md={1} className="d-none d-lg-block">
                                                    <div className="icon-seal"><FontAwesomeIcon icon={faBuilding} /></div>
                                                </Col>
                                                <Col sm={12} md={11}>
                                                    <h6 className="color-dark m-0"><strong>Razão Social</strong> <br /><span className="color-gray">{sealInformation.company_name}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center mb-4">
                                                <Col md={1} className="d-none d-lg-block">
                                                    <div className="icon-seal"><FontAwesomeIcon icon={faShieldAlt} /></div>
                                                </Col>
                                                <Col xs={12} md={11}>
                                                    <h6 className="color-dark m-0"><strong>Status</strong> <br /><span className="color-gray">Site certificado até <br /> <strong>{new Date(sealInformation.expire_date).toLocaleDateString("pt-BR", "DD/MM/YYYY")}</strong>.</span></h6>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} className="divisor-left">
                                            <h3 className="color-dark text-center mb-5">Visualizou o Selo SSL TrustSign? Então sua conexão está protegida.</h3>
                                            <Row className="text-center px-3">
                                                <Col xs={12} md={6}>
                                                    <h6 className="color-gray m-0">Verifique se o endereço da página que está sendo acessada inicia por HTTPS:// ou se o cadeado aparece no navegador está ativo.</h6>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <h6 className="color-gray m-0">Essas são as garantias que a página codifica todos os seus dados digitados e os transmite até os servidores do site de forma segura.</h6>
                                                </Col>
                                                <Col xs={12} className="mt-4">
                                                    <img src={SeloImg} className="img-fluid" alt="" />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                                <div className="my-5 bg-dark">
                                    <Container>
                                        <Row className="py-4">
                                            <Col xs={12} className="d-flex justify-content-center align-items-center">
                                                <h3 className="mb-0"><strong className="color-primary">INFORME</strong> UM SELO FALSO</h3>
                                                <a href="/consumidor/denuncie" rel="noreferrer" className="ms-4"><ButtonSB type="green-dark" btnName="INFORMAR" /></a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Container>
                                    <Row>
                                        <BlogNews />
                                    </Row>
                                    <Row className="padding-row">
                                        <Col xs={12} md={12}>
                                            <FormSB color="white" type="form-client" title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>
                        :
                        <>
                            <div className="section-height-100-center bg-dark-red">
                                <Container>
                                    <Row>
                                        <h2 className="text-center">Atenção! O site <strong className="break-line">{this.state.url}</strong> não é certificado. <br /> <strong>Informa à Site Blindado.</strong></h2>
                                    </Row>
                                </Container>
                            </div>
                            <div className="padding-container">
                                <Container>
                                    <Row>
                                        <Col xs={12}>
                                            <h2 className="color-dark text-center mb-5">Domínio inválido</h2>
                                            <h4 className="color-gray text-center">Encontrou um site que utiliza os selos da Site Blindado e não consta em nossa base de clientes? Por favor denuncie e torne a internet mais segura para todos.</h4>
                                        </Col>
                                    </Row>
                                </Container>
                                <div className="my-5 bg-dark">
                                    <Container>
                                        <Row className="py-4">
                                            <Col xs={12} className="d-flex justify-content-center align-items-center">
                                                <h3 className="mb-0"><strong className="color-primary">INFORME</strong> UM SELO FALSO</h3>
                                                <a href="/consumidor/denuncie" rel="noreferrer" className="ms-4"><ButtonSB type="green-dark" btnName="INFORMAR" /></a>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                                <Container>
                                    <Row>
                                        <BlogNews />
                                    </Row>
                                    <Row className="padding-row">
                                        <Col xs={12} md={12}>
                                            <FormSB color="white" type="form-client" title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </>
                    }
                </Layout>
                <Loading show={this.state.load} />
            </>
        );
    }
}
