import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Home from "./pages/Home";

// Produtos
import Blindagem from "./pages/Produtos/Blindagem";
import Pentest from "./pages/Produtos/Pentest";
import SSL from "./pages/Produtos/SSL";
import WAF from "./pages/Produtos/WAF";

// Shop
import SSLShop from "./pages/Shop/SSL";

// Sobre
import Sobre from "./pages/Sobre";

// Carreiras
import Carreiras from "./pages/Carreiras";

// Contato
import Contato from "./pages/Contato";
import QueroSerCliente from "./pages/QueroSerCliente";
import PaginaSucesso from "./pages/Contato/Sucesso";

// Consumidor
import ContratosServicos from "./pages/Consumidor/ContratosServicos";
import Denuncie from "./pages/Consumidor/Denuncie";
import FAQ from "./pages/Consumidor/FAQ";
import GuiaSeguranca from "./pages/Consumidor/GuiaSeguranca";
import PoliticaPrivacidade from "./pages/Consumidor/PoliticaPrivacidade";
import PoliticaPrivacidade2020 from "./pages/Consumidor/PoliticaPrivacidade/Versoes/2020";
import PoliticaPrivacidade2021 from "./pages/Consumidor/PoliticaPrivacidade/Versoes/2021";
import Verifique from "./pages/Consumidor/Verifique";
import Selo from "./pages/Consumidor/Selo";
import SeloSSL from "./pages/Consumidor/SeloSSL";
import SeloTrustSign from "./pages/Consumidor/SeloTrustSign";
import Obrigado from "./pages/Obrigado";

import PaginaSucessoConsumidor from "./pages/Consumidor/PaginaSucesso";

// Biblioteca
import Blog from "./pages/Blog";
import BlogSearch from "./pages/BlogSearch";
import SinglePost from "./pages/Blog/SinglePost";

import MateriaisRicos from "./pages/MateriaisRicos";

// Versão B
import HomeB from "./pages/VersaoB/Home";

const RootElement = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/"><Home /></Route>

            {/* Shop */}
            <Route path="/produtos/ssl/shop"><SSLShop /></Route>
            <Route path="/obrigado"><Obrigado /></Route>

            {/* Produtos */}
            <Route path="/produtos/blindagem"><Blindagem /></Route>
            <Route path="/produtos/pentest"><Pentest /></Route>
            <Route path="/produtos/ssl"><SSL /></Route>
            <Route path="/produtos/waf"><WAF /></Route>

            {/* Sobre */}
            <Route path="/sobre-nos"><Sobre /></Route>

            {/* Carreiras */}
            <Route path="/carreiras"><Carreiras /></Route>

            {/* Contato */}
            <Route path="/contato"><Contato /></Route>
            <Route path="/quero-ser-cliente-vtex"><QueroSerCliente /></Route>
            <Route path="/quero-ser-cliente-linx"><QueroSerCliente /></Route>
            <Route path="/quero-ser-cliente"><QueroSerCliente /></Route>
            <Route path="/sucesso/contato"><PaginaSucesso /></Route>

            {/* Consumidor */}
            <Route path="/consumidor/contratos-de-servicos"><ContratosServicos /></Route>
            <Route path="/consumidor/denuncie"><Denuncie /></Route>
            <Route path="/consumidor/faq"><FAQ /></Route>
            <Route path="/consumidor/guia-de-seguranca"><GuiaSeguranca /></Route>
            <Route path="/consumidor/politica-de-privacidade"><PoliticaPrivacidade /></Route>
            <Route path="/consumidor/versoes/2020/politica-de-privacidade"><PoliticaPrivacidade2020 /></Route>
            <Route path="/consumidor/versoes/2021/politica-de-privacidade"><PoliticaPrivacidade2021 /></Route>
            <Route path="/consumidor/verifique"><Verifique /></Route>
            <Route path="/consumidor/selo-blindado"><Selo /></Route>
            <Route path="/consumidor/selo-ssl"><SeloSSL /></Route>
            <Route path="/consumidor/selo-trust-sign"><SeloTrustSign /></Route>

            <Route path="/consumidor/pagina-sucesso"><PaginaSucessoConsumidor /></Route>


            {/* Biblioteca */}
            <Route path="/blog/posts/:keyword"><BlogSearch /></Route>
            <Route path="/blog/posts"><Blog /></Route>
            <Route path="/blog/single-post/:slug"><SinglePost /></Route>
            <Route path="/materiais-ricos"><MateriaisRicos /></Route>
            {/* SITE B */}
            <Route path="/2"><HomeB /></Route>

            {/* 404 */}
            <Route path="*"><Redirect to={{ pathname: "/" }} /></Route>
        </Switch>
    </BrowserRouter>
);

export default RootElement;
