import React, { Component } from "react";
import { Container, Row, Col, } from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faBuilding, faCalendarDay, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import Layout from "../../../components/Layout";
import FormSB from "../../../components/Form";
import ButtonSB from "../../../components/Button";
import BlogNews from "../../../components/BlogNews";
import Loading from "../../../components/Loading";

import SeloImg from '../../../assets/img/consumer/selo-site-blindado.png';

const EXCEPTIONS = [
    'www.lojasrenner.com.br', 'www.arquiteturadasflores.com.br', 'www.centralar.com.br',
    'www.vinhofacil.com.br', 'boxmagenta.com.br', 'hotmilhas.com.br', 'www.espacolaser.com.br',
    'www.onorah.com.br', 'www.lojagrendene.com.br', 'www.essentialnutrition.com.br',
    'www.noorskin.com.br', 'vinsel.com.br', 'demonstracao.siclope.com.br', 'www.melissa.com.br'
];

const SPECIAL_DOMAINS = [
    'puc-rio.br', 'americanas.com.br', 'submarino.com.br', 'negociafacil.com.br',
    'vidatrilegal.com.br', 'vidatrilegaltche.com.br', 'minascap.com', 'superminascap.com',
    'minascapnorte.com', 'supervalemg.com.br', 'sumicity.com.br', 'maxmilhas.com.br',
    'www.imovelsegurado.com.br', 'siteblindado.com', 'globalpoints.com.br',
    'stelladiamonds.com.br', 'supershoppingosasco.com.br', 'patiociane.com.br',
    'cea.com.br', 'urmobo.com.br', 'hotmilhas.com', '123milhas.com', 'www.dhcp.com.br',
    'negociamais.com.br'
];

export default class Selo extends Component {
    state = {
        sealInformation: [],
        url: '',
        load: false,
        referrerError: false,
    };

    componentDidMount () {
        this.initializeMetaTags();
        this.getSealInformation();
    }

    initializeMetaTags () {
        document.title = "Site Blindado - Selo Blindado";
        const metaTag = document.createElement('meta');
        metaTag.name = 'robots';
        metaTag.content = 'noindex';
        document.head.appendChild(metaTag);
    }

    getHostnameParameter = () => {
        return new URL(document.location).searchParams.get("hostname");
    }

    domainOrSubdomainExists (domain, domainList) {
        const parts = domain.split('.');
        for (let i = 0; i < parts.length - 1; i++) {
            const subDomain = parts.slice(i).join('.');
            if (domainList.includes(subDomain)) {
                return true;
            }
        }

        return false;
    }

    determineHost = (url) => {
        const referrer = document.referrer;
        if (referrer) {
            const match = referrer.match(/:\/\/(.[^/]+)/);
            if (match) return match[1];
        }
        if (referrer === '') {
            console.log("entrou no !referrer || referrer === ''", url, referrer);
            this.setState({ referrerError: true });
            if (this.domainOrSubdomainExists(url, EXCEPTIONS) || this.domainOrSubdomainExists(url, SPECIAL_DOMAINS)) {
                this.setState({ referrerError: false });
                return url;
            }
        } else {
            return '-';
        }
    }

    fetchSealInformation = async (host) => {
        const path = new URL(document.location).searchParams.get("path");
        const url = path
            ? `https://api.siteblindado.com/v1/seal/aw/${host}/info?path=${path}`
            : `https://seal.siteblindado.com/${host}/info`;

        try {
            const response = await axios.get(url);
            this.setState({ sealInformation: response.data, load: false });
        } catch (error) {
            this.setState({ load: false, url: host });
        }
    }

    getSealInformation = async () => {
        this.setState({ load: true });
        const url = this.getHostnameParameter();
        if (!url) return;

        const host = this.determineHost(url);
        console.log(host);
        await this.fetchSealInformation(host);
    };

    render () {
        const { sealInformation, url } = this.state;

        return (
            <>
                <Layout>
                    {this.state.referrerError ?
                        <>
                            <>
                                <div className="section-height-100-center bg-dark-blue">
                                    <Container>
                                        <Row>
                                            <h2 className="text-center">Atenção! O <strong>site</strong> onde você tentou validar a blindagem <strong>não foi reconhecido corretamente</strong>. Para confirmar a blindagem, por favor, <strong>acesse o site do parceiro</strong> e <strong>clique no nosso selo</strong>.</h2>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="padding-container">
                                    <Container>
                                        <Row>
                                            <Col xs={12}>
                                                <h2 className="color-dark text-center mb-5">Domínio inválido</h2>
                                                <h4 className="color-gray text-center">Encontrou um site que utiliza os selos da Site Blindado e não consta em nossa base de clientes? Por favor denuncie e torne a internet mais segura para todos.</h4>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div className="my-5 bg-dark">
                                        <Container>
                                            <Row className="py-4">
                                                <Col xs={12} className="d-flex justify-content-center align-items-center">
                                                    <h3 className="mb-0"><strong className="color-primary">INFORME</strong> UM SELO FALSO</h3>
                                                    <a href="/consumidor/denuncie" rel="noreferrer" className="ms-4"><ButtonSB type="green-dark" btnName="INFORMAR" /></a>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <Container>
                                        <Row>
                                            <BlogNews />
                                        </Row>
                                        <Row className="padding-row">
                                            <Col xs={12} md={12}>
                                                <FormSB color="white" type="form-client" source="Página de Selo Blindado" title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </>
                        </>
                        :
                        sealInformation.enabled ?
                            <>
                                <div className="section-height-100-center bg-dark-green">
                                    <Container>
                                        <Row>
                                            <h2 className="text-center">O site <strong className="break-line">{url !== 'www.siteblindado.com' ? sealInformation.device : 'www.siteblindado.com'}</strong> foi auditado e está <strong>BLINDADO</strong> contra vulnerabilidades de segurança.</h2>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="padding-container">
                                    <Container>
                                        <Row>
                                            <Col xs={12} md={6} className="mb-5 mb-md-0">
                                                <h3 className="color-dark text-center mb-5">Detalhes da Auditoria</h3>
                                                <Row className="align-items-center mb-4">
                                                    <Col md={1} className="d-none d-lg-block">
                                                        <div className="icon-seal"><FontAwesomeIcon icon={faAward} /></div>
                                                    </Col>
                                                    <Col sm={12} md={11}>
                                                        <h6 className="color-dark m-0"><strong>Site Auditado</strong> <br /><span className="color-gray">{
                                                            (url !== 'www.siteblindado.com' ? sealInformation.device : 'www.siteblindado.com')
                                                        }</span></h6>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-4">
                                                    <Col md={1} className="d-none d-lg-block">
                                                        <div className="icon-seal"><FontAwesomeIcon icon={faBuilding} /></div>
                                                    </Col>
                                                    <Col sm={12} md={11}>
                                                        <h6 className="color-dark m-0"><strong>Razão Social</strong> <br /><span className="color-gray">{
                                                            (url !== 'www.siteblindado.com' ? sealInformation.company_name : 'BIT SERVICES INOVACAO E TECNOLOGIA LTDA')
                                                        }</span></h6>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-4">
                                                    <Col md={1} className="d-none d-lg-block">
                                                        <div className="icon-seal"><FontAwesomeIcon icon={faCalendarDay} /></div>
                                                    </Col>
                                                    <Col sm={12} md={11}>
                                                        <h6 className="color-dark m-0"><strong>Última busca de vulnerabilidades</strong> <br /><span className="color-gray">{
                                                            (url !== 'www.siteblindado.com' ? new Date(sealInformation.last_execution_date).toLocaleDateString("pt-BR", "DD/MM/YYYY") : new Date().toLocaleDateString("pt-BR", "DD/MM/YYYY"))
                                                        }</span></h6>
                                                    </Col>
                                                </Row>
                                                <Row className="align-items-center mb-4">
                                                    <Col md={1} className="d-none d-lg-block">
                                                        <div className="icon-seal"><FontAwesomeIcon icon={faShieldAlt} /></div>
                                                    </Col>
                                                    <Col xs={12} md={11}>
                                                        <h6 className="color-dark m-0"><strong>Status</strong> <br /><span className="color-gray">Segurança Auditada e Aprovada contra: <br /> As <a href="https://blog.siteblindado.com/top-10-owasp-o-que-e-e-quais-sao/" target="blank" rel="noreferrer" className="text-decoration-none"><strong>vulnerabilidades de segurança</strong></a> mais críticas.</span></h6>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={6} className="divisor-left">
                                                <h3 className="color-dark text-center mb-5">Visualizou o Selo Site Blindado? Compre sem medo!</h3>
                                                <Row className="text-center px-3">
                                                    <Col xs={12} md={6}>
                                                        <h6 className="color-gray m-0">O Selo Site Blindado somente fica visível em sites aprovados em nossos milhares de testes diarios.</h6>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <h6 className="color-gray m-0">Nossos testes buscam brechas de segurança que possam permitir ataques reais de hackers.</h6>
                                                    </Col>
                                                    <Col xs={12} className="mt-4">
                                                        <img src={SeloImg} className="img-fluid" alt="" />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div className="my-5 bg-dark">
                                        <Container>
                                            <Row className="py-4">
                                                <Col xs={12} className="d-flex justify-content-center align-items-center">
                                                    <h3 className="mb-0"><strong className="color-primary">INFORME</strong> UM SELO FALSO</h3>
                                                    <a href="/consumidor/denuncie" rel="noreferrer" className="ms-4"><ButtonSB type="green-dark" btnName="INFORMAR" /></a>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <Container>
                                        <Row>
                                            <BlogNews />
                                        </Row>
                                        <Row className="padding-row">
                                            <Col xs={12} md={12}>
                                                <FormSB color="white" type="form-client" source="Página de Selo Blindado" title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </>
                            :
                            <>
                                <div className="section-height-100-center bg-dark-red">
                                    <Container>
                                        <Row>
                                            <h2 className="text-center">Atenção! O site <strong>{this.state.url} não foi testado nos padrões de segurança da Site Blindado</strong>.</h2>
                                        </Row>
                                    </Container>
                                </div>
                                <div className="padding-container">
                                    <Container>
                                        <Row>
                                            <Col xs={12}>
                                                <h2 className="color-dark text-center mb-5">Domínio inválido</h2>
                                                <h4 className="color-gray text-center">Encontrou um site que utiliza os selos da Site Blindado e não consta em nossa base de clientes? Por favor denuncie e torne a internet mais segura para todos.</h4>
                                            </Col>
                                        </Row>
                                    </Container>
                                    <div className="my-5 bg-dark">
                                        <Container>
                                            <Row className="py-4">
                                                <Col xs={12} className="d-flex justify-content-center align-items-center">
                                                    <h3 className="mb-0"><strong className="color-primary">INFORME</strong> UM SELO FALSO</h3>
                                                    <a href="/consumidor/denuncie" rel="noreferrer" className="ms-4"><ButtonSB type="green-dark" btnName="INFORMAR" /></a>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <Container>
                                        <Row>
                                            <BlogNews />
                                        </Row>
                                        <Row className="padding-row">
                                            <Col xs={12} md={12}>
                                                <FormSB color="white" type="form-client" source="Página de Selo Blindado" title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </>
                    }
                </Layout>
                <Loading show={this.state.load} />
            </>
        );
    }
}
