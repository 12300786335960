import React, { Component } from "react";
import { Container, Row, Col, Card, CardGroup } from "react-bootstrap";
import CountUp from 'react-countup';
import Layout from "../../components/Layout";
import ArrowButton from "../../components/ArrowButton";
import ButtonSB from "../../components/Button";
import FormSB from "../../components/Form";
import BlogNews from "../../components/BlogNews";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';

// images
import Pentest from "../../assets/img/file-computer.png";
import Blindagem from "../../assets/img/shield-check.png";
import WAF from "../../assets/img/wall-fire.png";
import SSL from "../../assets/img/padlock.png";
import HomeGraph from "../../assets/img/home/home-graph.jpg";
import TalkToUs from "../../assets/img/home/fale-conosco.png";
import CNBB from "../../assets/img/cnbb.png";
import Fini from "../../assets/img/fini.png";
import Ultrafarma from "../../assets/img/ultrafarma.png";
import Kabum from "../../assets/img/kabum.png";

export default class Home extends Component {
    state = {
        clientActive: 1,
        clients: [
            {
                position: 1, name: 'Ultrafarma', personName: 'Ricardo Vieira - Diretor de E-commerce UltraFarma',
                testimonial: '“A mesma tranquilidade que nossos clientes têm quando veem o selo da Site Blindado em nosso e-commerce, é compartilhada por nossa equipe quando se trata dos serviços da Site Blindado.”'
            },
            {
                position: 2, name: 'Kabum', personName: 'Felipe Escudeiro - CTO KaBuM!',
                testimonial: '“O comprometimento do time do Site Blindado nos proporciona tranquilidade e eficiência. Além de fornecer um serviço com qualidade, capaz de garantir a segurança do nosso site, a parceria aumenta a confiabilidade de nossos clientes e parceiros.”'
            },
            {
                position: 3, name: 'Fini Store', personName: 'Erica Mendes - Coord. de E-commerce Fini Store',
                testimonial: '“A Site Blindado atende todas as nossas expectativas. Possui uma equipe de suporte ágil, qualificada e comprometida, garantindo para nós e nossos clientes um serviço eficiente e seguro.”'
            },
            {
                position: 4, name: 'Edições CNBB', personName: 'Carlos Martins - Projetos EDIÇÕES CNBB - Conferência Nacional dos Bispos do Brasil',
                testimonial: '“Acreditamos na Site Blindado e temos em vocês um parceiro forte e que nos deixa ter “um sono tranquilo”, pois antes de vocês sofremos vários ataques e tivemos dificuldades de performance que hoje não temos mais.”'
            }
        ],
        data: [],
        actualClient: {}
    }

    componentDidMount () {
        this.setState({ actualClient: this.state.clients[0] });
        document.title = "Site Blindado - Home";
    }

    nextClient = () => {
        const { clientActive } = this.state;
        if (clientActive === 4) {
            this.chooseClient(1);
        } else {
            let newNumber = clientActive + 1;
            this.chooseClient(newNumber);
        }
    }

    chooseClient = (position) => {
        switch (position) {
            case 1:
                this.setState({ actualClient: this.state.clients[0], clientActive: 1 })
                break;
            case 2:
                this.setState({ actualClient: this.state.clients[1], clientActive: 2 })
                break;
            case 3:
                this.setState({ actualClient: this.state.clients[2], clientActive: 3 })
                break;
            case 4:
                this.setState({ actualClient: this.state.clients[3], clientActive: 4 })
                break;
            default:
                break;
        }
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        const { actualClient } = this.state;

        return (
            <Layout>
                <div className="section-height-100-center bg-dark-sb home-sess1">
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <h6 className="color-primary font-semi-bold">Blindar de verdade</h6>
                                <h1 className="font-black">Proteger, <br />monitorar <br /> e defender<span className="color-primary">.</span></h1>
                                <h6 className="subtitle font-light mb-5">Soluções robustas em cibersegurança. <br />Proteção digital completa para sua empresa.</h6>
                                <ArrowButton link="/#products" type="green" side="left" text="Pentest, WAF, Blindagem e muito mais" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container join-home" id="products">
                    <Container>
                        <Row>
                            <Col xs={12} md={3}>
                                <Row className="h-100">
                                    <Col xs={12} className="text-center-mobile">
                                        <img src={Pentest} className="img-fluid mb-4" alt="" style={{ maxHeight: 56 }} />
                                        <p className="color-secondary font-semi-bold mb-4">Pentest</p>
                                        <p>Realizado por nosso especialista em segurança, o teste de invasão <span className="font-semi-bold">identifica vulnerabilidades</span> que não são percebidas por ferramentas automatizadas.</p>
                                    </Col>
                                    <div className="flex-end">
                                        <ArrowButton link="/produtos/pentest" type="green" side="right" />
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className="h-100">
                                    <Col xs={12} className="text-center-mobile">
                                        <img src={Blindagem} className="img-fluid mb-4" alt="" style={{ maxHeight: 56 }} />
                                        <p className="color-secondary font-semi-bold mb-4">Blindagem de sites</p>
                                        <p>Cuidado: nem todo certificado do mercado garante uma <span className="font-semi-bold">verdadeira blindagem</span>. Mas com a Site Blindado seu negócio estará seguro.</p>
                                    </Col>
                                    <div className="flex-end">
                                        <ArrowButton link="/produtos/blindagem" type="green" side="right" />
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className="h-100">
                                    <Col xs={12} className="text-center-mobile">
                                        <img src={WAF} className="img-fluid mb-4" alt="" style={{ maxHeight: 56 }} />
                                        <p className="color-secondary font-semi-bold mb-4">WAF</p>
                                        <p>Tenha proteção ativa <span className="font-semi-bold">contra ataques e invasões</span> em aplicações web. Este serviço funciona como uma barreira de segurança contra a ação de invasores.</p>
                                    </Col>
                                    <div className="flex-end">
                                        <ArrowButton link="/produtos/waf" type="green" side="right" />
                                    </div>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className="h-100">
                                    <Col xs={12} className="text-center-mobile">
                                        <img src={SSL} className="img-fluid mb-4" alt="" style={{ maxHeight: 56 }} />
                                        <p className="color-secondary font-semi-bold mb-4">Certificados SSL</p>
                                        <p><span className="font-semi-bold">Protegem os dados enviados</span> por seus clientes ao embaralhar as informações de forma que só o servidor possa interpretá-los.</p>
                                    </Col>
                                    <div className="flex-end">
                                        <ArrowButton link="/produtos/ssl" type="green" side="right" />
                                    </div>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="padding-row justify-content-center align-items-center">
                            <Col xs={12} md={12}>
                                <Card className="bg-dark-green home-landingpage-banner" style={{ padding: "4rem 14rem" }}>
                                    <h3 className="text-center"><strong>Não coloque em risco a segurança do seu site!</strong></h3>
                                    <h5 className="text-center">
                                        Ao adquirir o SSL, tenha a tranquilidade de saber que seu site está protegido contra ameaças cibernéticas.<br />
                                        <br /><strong>Clique abaixo para adquirir o seu certificado SSL agora mesmo!</strong>
                                    </h5>
                                    <div className="text-center" style={{ marginTop: "40px" }}>
                                        <a href="/produtos/ssl">
                                            <Button className={"btn-green-dark"} style={{ color: "#000", fontSize: 20, height: "auto" }}><span style={{ padding: "30px" }}><strong>Proteja Meu Site Agora <FontAwesomeIcon icon={faLock} /></strong></span></Button>
                                        </a>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="padding-row">
                            <Col xs={12} md={6} className="mb-md-0 mb-5">
                                <h3 className="font-semi-bold text-dark mb-5">Proteção ininterrupta, em<br /> <span className="color-gradient">qualquer lugar</span> do planeta.</h3>
                                <h6 className="text-dark mb-5">Sistemas, sites e aplicativos estão sob constantes ataques. <span className="font-semi-bold">Conte com a precisão das soluções Site Blindado.</span></h6>
                                <a href="#form" rel="noreferrer"><ButtonSB type="green-dark" btnName="FALE CONOSCO" /></a>
                            </Col>
                            <Col xs={12} md={6}>
                                <img src={HomeGraph} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                        <Row className="padding-row justify-content-center">
                            <Col xs={12} md={12}>
                                <h3 className="text-dark font-medium text-center">Mais de <span className="font-bold">5.000</span> empresas, incluindo gigantes do varejo, contam com nossas soluções de segurança digital!</h3>
                            </Col>
                        </Row>
                        <Row className="padding-row" id="statement">
                            <Col xs={12} md={12}>
                                <Row className="align-items-center">
                                    <Col xs={12} md={6}>
                                        <h1 className="text-lg text-dark font-black text-center-mobile">
                                            EXPE<br /> RIÊN<br /> CIAS <br /> <span className="color-gradient">REAIS.</span>
                                        </h1>
                                    </Col>
                                    <Col xs={12} md={6} className="bg-experiences">
                                        <h3 className="mb-5">{actualClient.name}</h3>
                                        <p className="color-white font-light mb-5">{actualClient.testimonial} <br /><br /><span className="font-bold">{actualClient.personName}</span></p>
                                        <div onClick={() => this.nextClient()} className="c-pointer"><ArrowButton type="green" side="left" text="Próximo" /></div>
                                        <div className="mt-5"><a href="/sobre-nos"><ButtonSB type="green" btnName="conheça todos os nossos clientes" /></a></div>
                                    </Col>
                                </Row>
                                <Row className="d-none d-lg-block">
                                    <CardGroup className="p-0 card-group-clients">
                                        <Card onClick={() => this.chooseClient(1)}>
                                            {this.state.clientActive === 1 && <div className="active-card" />}
                                            <img src={Ultrafarma} className="img-fluid" alt="" />
                                        </Card>
                                        <Card onClick={() => this.chooseClient(2)}>
                                            {this.state.clientActive === 2 && <div className="active-card" />}
                                            <img src={Kabum} className="img-fluid" alt="" />
                                        </Card>
                                        <Card onClick={() => this.chooseClient(3)}>
                                            {this.state.clientActive === 3 && <div className="active-card" />}
                                            <img src={Fini} className="img-fluid" alt="" />
                                        </Card>
                                        <Card onClick={() => this.chooseClient(4)}>
                                            {this.state.clientActive === 4 && <div className="active-card" />}
                                            <img src={CNBB} className="img-fluid" alt="" />
                                        </Card>
                                    </CardGroup>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container home-sess2">
                    <Container>
                        <Row className="justify-content-center align-items-center">
                            <Col xs={12} md={6}>
                                <h3 className="font-black color-primary mb-5">SUA EMPRESA TAMBÉM PODE CONTAR COM AS NOSSAS SOLUÇÕES</h3>
                                <h6 className="mb-5">Seja para pequenas, médias ou grandes empresas, a <span className="font-semi-bold">segurança digital é essencial para o sucesso do negócio</span>. Nossos produtos ajudarão a alcançar novos níveis de proteção.</h6>
                                <ArrowButton link="/#form" type="green" side="left" text="fale conosco" />
                            </Col>
                            <Col xs={12} md={6} className="text-right d-none d-lg-block">
                                <img src={TalkToUs} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <h3 className="text-dark font-black">Por que seu negócio precisa da <span className="color-gradient">Site Blindado?</span></h3>
                            </Col>
                        </Row>
                        <Row className="text-center-mobile mt-md-0 mt-4">
                            <Col xs={12} md={4}>
                                <h1 className="text-lg font-black title-outline-green"><CountUp end={83} duration={3} />%</h1>
                                <p>Dos sites apresentam <span className="font-semi-bold">brechas de segurança</span> críticas identificadas na primeira análise da Site Blindado</p>
                            </Col>
                            <Col xs={12} md={4}>
                                <h1 className="text-lg font-black title-outline-green"><CountUp end={86} duration={3} />%</h1>
                                <p>Das empresas brasileiras aumentaram o orçamento em segurança da informação e adequação à LGPD no ano de 2020</p>
                            </Col>
                            <Col xs={12} md={4}>
                                <h1 className="text-lg font-black title-outline-green"><CountUp end={54} duration={3} />%</h1>
                                <p>Dos consumidores acreditam que pagamentos realizados offline são mais seguros que os pagamentos online</p>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <BlogNews />
                        </Row>
                        <Row className="padding-row" id="form">
                            <Col xs={12} md={12}>
                                <FormSB color="white" parentCallback={this.callbackFunction} type="form-contact" title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que nossa equipe possa entrar em contato com você e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> disponível no mercado.</>} />
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={12}>
                                <Card className="card-home-verify">
                                    <Row className="h-100 align-items-center text-center-mobile">
                                        <Col xs={12} md={8}>
                                            <h4 className="text-medium text-center m-0">O seu site é blindado? Clique aqui e verifique.</h4>
                                        </Col>
                                        <Col xs={12} md={4} className="mt-md-0 mt-4">
                                            <a href="/consumidor/verifique" rel="noreferrer"><ButtonSB type="black" btnName="verifique aqui" /></a>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout >
        );
    }
}
