import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { linkRenderer } from "../../../services/utils";
import ReactMarkdown from 'react-markdown'
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../../components/Layout";
import ButtonSB from "../../../components/Button";
import InstagramPosts from "../../../components/InstagramPosts";
import Loading from "../../../components/Loading";
import { getContentfulCategory, getContentfulHeroImg, getContentfulAuthor, getContentfulArticleBySlug, getContentfulAuthorImage } from '../../../services/blogService'

function SinglePost() {
    const [load, setLoad] = useState(false);
    const [post, setPost] = useState({});
    const { slug } = useParams();

    useEffect(() => {
        const getPost = async () => {
            setLoad(true);
            try {
                const [postRes] = await Promise.all([getContentfulArticleBySlug(slug)])
                if (postRes.items.length === 0) {
                    window.location.replace(`http://${window.location.host}`);
                }
                const article = postRes.items[0].fields;
                const [categoryRes, imgRes, authorRes] = await Promise.all([
                    getContentfulCategory(article.category.sys.id),
                    getContentfulHeroImg(article.heroImage.sys.id),
                    getContentfulAuthor(article.author.sys.id)
                ]);
                const [authorImageRes] = await Promise.all([getContentfulAuthorImage(authorRes.fields.thumbnail.sys.id)])

                const category = categoryRes.fields.name;
                const img = "https:" + imgRes.fields.file.url;
                const author = authorRes.fields.name;
                const authorImage = "https:" + authorImageRes.fields.file.url;

                const processedPost = {
                    ...article,
                    category,
                    image: img,
                    author,
                    authorImage
                };

                // Atualiza o meta-description com o resumo do post
                const summary = article.resume;
                const metaTag = document.querySelector('meta[name="description"]');
                if (metaTag) {
                    metaTag.setAttribute('content', summary ? summary : '');
                }

                document.title = `Site Blindado - Blog - ${processedPost.title}`;
                setPost(processedPost);
                setLoad(false);
            } catch (error) {
                console.error(error);
                setLoad(false);
            }
        };

        document.title = "Site Blindado - Post";
        getPost();
    }, [slug]);

    return (
        <>
            <Layout>
                <div className="padding-container bg-dark-green">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">{post?.title || 'Carregando post...'}</h1>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <Col xs={12} md={9} className="pe-5">
                                <Row>
                                    <Col xs={12}>
                                        <p className="font-semi-bold">{post.category}</p>
                                        <Row className="img-blog">
                                            <Col xs={12} className="p-0">
                                                <img src={post.image} className="img-fluid img-stretch w-100 c-pointer" alt="" />
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col xs={12}>
                                                <h3 className="color-secondary font-bold mb-5">{post?.title}</h3>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="body-blog">
                                                    <ReactMarkdown components={{ a: linkRenderer }}>{post?.body || ''}</ReactMarkdown>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col xs={12} md={6} className="d-flex align-items-center">
                                        <div className="avatar-image me-3">
                                            <img src={post.authorImage} className="img-fluid img-stretch" alt="" />
                                        </div>
                                        <h6 className="font-medium color-dark mb-0">Por @<strong>{post.author}</strong></h6>
                                    </Col>
                                    <Col xs={12} md={6} className="d-flex align-items-center justify-content-end">
                                        <h6 className="font-regular color-gray mb-0">{post?.date && (new Date(post.date).toLocaleDateString("pt-BR"))}</h6>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={3}>
                                <Row className="mb-5">
                                    <Col xs={12} md={12}>
                                        <h5 className="font-semi-bold color-dark mb-3">Site Blindado</h5>
                                        <p className="font-light color-gray">A Site Blindado é líder em segurança para e-commerce na América Latina, oferecendo soluções em SSL, Blindagem de Sites, WAF e Pentest. Nesse blog trazemos as principais dicas de como se proteger ao navegar pela internet, além de como as empresas podem melhorar a segurança de seus sites e informações.</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <InstagramPosts type='sidebar' title='Instagram' />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col xs={12} className="mt-5 d-flex justify-content-center align-items-center">
                                <h4 className="color-dark font-medium mb-0 me-5">Quer ser Blindado? Conheça nossas soluções</h4>
                                <a href="/quero-ser-cliente" rel="noreferrer"><ButtonSB type="black" btnName={"CLIQUE AQUI"} /></a>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
            <Loading show={load} />
        </>
    );
}

export default SinglePost;
