import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";

import Button from "../../components/Button";
import ContentfulPagination from "../../components/ContenfulPagination";
import Layout from "../../components/Layout";
import Loading from "../../components/Loading";
import {
  getRichContentAsset,
  getRichContentSearchedCategory,
} from "../../services/materialRicoService";

function MateriaisRicos() {
  const [materiais, setMateriais] = useState([]);
  const [lead, setLead] = useState([]);
  const [pagination, setPagination] = useState({});
  const [load, setLoad] = useState(false);
  const [catefory, setCategory] = useState("");

  const [modal, setModal] = useState({ show: false, pdfUrl: "" });

  const handleClose = () => {
    setModal({ show: false, pdfUrl: "" });
    setLead([]);
  };
  const handleShow = (url) => {
    const sessionLead = sessionStorage.getItem("downloadRichMaterial");
    if (sessionLead && sessionLead === "true") {
      window.open(url, "_blank");
      return;
    }
    setModal({ show: true, pdfUrl: url });
  };

  const getMateriais = useCallback(
    async (skip, page) => {
      setLoad(true);
      try {
        const [contentful] = await Promise.all([
          getRichContentSearchedCategory(skip, catefory),
        ]);
        const items = contentful.items;

        const requests = items.map(async (item) => {
          const coverId = item.fields.cover.sys.id;
          const pdfId = item.fields.pdf.sys.id;
          const categoria = item.fields.categoria[0];
          const [coverRes, pdfRes] = await Promise.all([
            getRichContentAsset(coverId),
            getRichContentAsset(pdfId),
          ]);

          const cover = "https:" + coverRes?.fields?.file?.url;
          const pdf = "https:" + pdfRes?.fields?.file?.url;

          return {
            ...item,
            categoria,
            coverUrl: cover,
            pdfUrl: pdf,
          };
        });

        const processedMateriais = await Promise.all(requests);

        const totalPages = Math.ceil(contentful.total / contentful.limit);
        const paginate = {
          total: contentful.total,
          active: page,
          pages: totalPages,
        };
        setPagination(paginate);
        setMateriais(processedMateriais);
        setLoad(false);
      } catch (error) {
        console.log(error);
        setLoad(false);
      }
    },
    [catefory]
  );

  useEffect(() => {
    document.title = "Site Blindado - Materiais Ricos";
    getMateriais(0, 1);
  }, [getMateriais]);

  const sendLead = async () => {
    const form = document.getElementById("materialForm");
    const validate = form.reportValidity();
    if (!validate) {
      return;
    }

    if (lead.documentNumber !== undefined) {
      lead.documentNumber = lead.documentNumber.replace(/\D/g, "");
    }

    setLoad(true);
    await axios
      .post(`${process.env.REACT_APP_SB_LEADS_URI}/consumidor/forms/material-rico`, lead)
      .then((res) => {
        setLoad(false);
        sessionStorage.setItem("downloadRichMaterial", true);
        Swal.fire({
          title: "Sucesso!",
          text: "Estamos liberando o seu material!",
          icon: "success",
        }).then((result) => {
          handleClose();
          window.open(modal.pdfUrl, "_blank").focus();
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Erro!",
          text: "Tivemos problemas em enviar sua solicitação. Faça novamente mais tarde.",
          icon: "error",
        });
        sessionStorage.setItem("downloadRichMaterial", false);
        setLoad(false);
      });
    setLead({});
  };

  const myChangeHandlerFormLead = (event) => {
    if (event.target.name === "phone") {
      event.target.value = event.target.value.replace(/\D/g, "");
      event.target.value = event.target.value.replace(
        /^(\d{2})(\d)/g,
        "($1) $2"
      );
      event.target.value = event.target.value.replace(/(\d)(\d{4})$/, "$1-$2");
      setLead({ ...lead, [event.target.name]: event.target.value });
    } else {
      setLead({ ...lead, [event.target.name]: event.target.value });
    }
  };

  return (
    <>
      <Layout>
        <div className="padding-container mobile-height-100 bg-dark-green">
          <Container>
            <Row className="align-items-center">
              <Col xs={12} md={8}>
                <h3 className="font-black mb-4">
                  <span className="color-primary">Site Blindado Academy:</span>{" "}
                  <br />
                  materiais gratuitos para ajudar na segurança online da sua
                  marca<span className="color-primary">.</span>
                </h3>
                <p className="color-white">
                  Enriqueça seu conhecimento em segurança digital!
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="padding-container">
          <Container>
            <Row>
              <Col className="pe-lg-5">
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center mb-3"
                  >
                    <h4 className="font-medium color-dark text-center">
                      Navegue pela nossa galeria de conteúdos
                      <br />
                      criados por nossos especialistas
                    </h4>
                  </Col>
                </Row>

                <Row className="mb-5 d-flex justify-content-center">
                  <Col xs={12} md={3}>
                    <Form.Select
                      style={{ accentColor: "#7bea19" }}
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      <option value="">Filtre por categoria</option>
                      <option value="e-book">E-book</option>
                      <option value="infográfico">Infográfico</option>
                      <option value="white-paper">White-Paper</option>
                    </Form.Select>
                  </Col>
                </Row>

                <Row>
                  {materiais.length ? (
                    materiais.map((material, index) => (
                      <Col xs={12} md={4} key={index} className="mb-4">
                        <Card className="card-blog-news">
                          <Row>
                            <Col xs={12} md={12}>
                              <a
                                onClick={() => handleShow(material.pdfUrl)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={material.coverUrl}
                                  className="img-fluid img-stretch img-blog-card"
                                  alt=""
                                />
                              </a>
                            </Col>
                          </Row>
                          <Row className="h-100">
                            <Col xs={12} md={12} className="description-news">
                              <Row className="h-100">
                                <Col xs={6}>
                                  <p className="font-semi-bold text-left text-uppercase">
                                    {material.categoria}
                                  </p>
                                </Col>
                                <Col xs={6}>
                                  <p className="font-light color-gray text-right">
                                    {new Date(
                                      material.fields.date
                                    ).toLocaleDateString("pt-BR", "DD/MM/YYYY")}
                                  </p>
                                </Col>
                                <h5 className="font-semi-bold color-dark mb-4">
                                  {material.fields.title}
                                </h5>
                                <p className="text-justify font-medium mb-4">
                                  {material.fields.description}
                                </p>
                                <div className="flex-end">
                                  {/* <Button
                                  // onClick={() => handleShowModal(material.pdfUrl)}]

                                  type='black'
                                  btnName='DOWNLOAD'
                                /> */}
                                  <Button
                                    onClick={() => handleShow(material.pdfUrl)}
                                    type="black"
                                    btnName="DOWNLOAD"
                                  />
                                </div>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <p className="text-center">
                      Não foram encontrados materiais disponíveis.
                    </p>
                  )}
                </Row>
                <Row>
                  <Col
                    xs={12}
                    md={12}
                    className="d-flex justify-content-center"
                  >
                    <ContentfulPagination
                      currentPage={pagination.active}
                      totalPages={pagination.pages}
                      visiblePages={5}
                      onPageChange={getMateriais}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Modal show={modal.show} onHide={handleClose} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title className="font-medium color-dark m-0">
                  Download de Material
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <p className="font-medium color-dark">
                    Após o preenchimento das informações o material será
                    disponibilizado.
                  </p>
                  <Form id="materialForm">
                    <Row className="mb-3">
                      <Col xs={12} md={6}>
                        <Form.Label className="color-dark">*Nome</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={myChangeHandlerFormLead}
                          name="name"
                          required
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Label className="color-dark">*E-mail</Form.Label>
                        <Form.Control
                          type="email"
                          onChange={myChangeHandlerFormLead}
                          name="email"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={6}>
                        <Form.Label className="color-dark">*CNPJ</Form.Label>
                        <NumberFormat
                          className="form-control"
                          format="##.###.###/####-##"
                          onChange={myChangeHandlerFormLead}
                          name="documentNumber"
                          required
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Label className="color-dark">*Cargo</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={myChangeHandlerFormLead}
                          name="role"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={6}>
                        <Form.Label className="color-dark">*Celular</Form.Label>
                        <NumberFormat
                          className="form-control"
                          format="(##) # ####-####"
                          onChange={myChangeHandlerFormLead}
                          name="cellPhone"
                          required
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Label className="color-dark">
                          *Telefone
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={myChangeHandlerFormLead}
                          name="phone"
                          maxLength="15"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={12}>
                        <Form.Label className="color-dark">
                          *URL do seu site
                        </Form.Label>
                        <Form.Control
                          type="text"
                          onChange={myChangeHandlerFormLead}
                          name="website"
                          required
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12} md={12}>
                        <Form.Label className="color-dark">Mensagem</Form.Label>
                        <Form.Control
                          as="textarea"
                          onChange={myChangeHandlerFormLead}
                          name="message"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col
                        xs={12}
                        md={12}
                        className="text-right mt-md-0 mt-4 text-center-mobile"
                      >
                        <Button
                          type={"green"}
                          onClick={sendLead}
                          btnName="ENVIAR"
                        />
                        <span style={{ marginRight: 1 + "rem" }}></span>
                        <Button
                          type={"black"}
                          onClick={handleClose}
                          btnName="CANCELAR"
                        />
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </Modal.Body>
            </Modal>
          </Container>
        </div>

        {/* <div id="form" className={`padding-container bg-color-secondary`}>
          <Container>
            <Row className='justify-content-center'>
              <Col xs={12} md={8}>
                <h3 className='color-white text-center font-black mb-4'>
                  Vamos construir juntos um ambiente<br />
                  online mais seguro e protegido!
                </h3>
                <p className='color-dark text-center mb-4'>
                  A Site Blindado acredita na importância de compartilhar conhecimento para<br />
                  fortalecer a segurança digital de toda a comunidade online.
                </p>
                <p className='color-dark text-center mb-5'>
                  Cadastre-se para receber nossos novos materiais!
                </p>
                <Row className='mb-5'>
                  <Col xs={12} md={6}>
                    <Form.Control type="text" placeholder="Nome" />
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Control type="email" placeholder="E-mail corporativo" />
                  </Col>
                </Row>
                <Row className='justify-content-center'>
                  <Col xs={12} md={4}>
                    <div className="d-grid gap-2">
                      <Button variant="dark">RECEBER NOVOS MATERIAIS</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div> */}
      </Layout>
      <Loading show={load} />
    </>
  );
}
export default MateriaisRicos;
