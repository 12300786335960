import React, { Component } from "react";
import { Row, Col, Card } from "react-bootstrap";
import axios from "axios";
import bannerInstagram from "../../assets/img/about/banner-instagram.png";


const INSTAGRAM_TOKEN = process.env.REACT_APP_INSTAGRAM_TOKEN;
export default class InstagramPosts extends Component {
    state = {
        posts: [],
    }

    componentDidMount = async () => {
        await axios.get(`https://graph.instagram.com/me/media?fields=media_url,permalink&access_token=${INSTAGRAM_TOKEN}&limit=4`)
            .then(async (res) => {
                this.setState({ posts: res.data.data });
            }).catch(err => {
                console.log(err);
            });
    }

    render() {
        const { posts } = this.state;
        return (
            <>
                {this.props.type === 'container' &&
                    <Col xs={12} md={12}>
                        {this.props.title &&
                            <Row>
                                <Col xs={12} className="mb-4">
                                    <h3 className="color-dark">
                                        {this.props.title}
                                    </h3>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} md={12} className="instagram-banner">
                                <a href="https://www.instagram.com/siteblindado/" target="_blank" rel="noreferrer"><img src={bannerInstagram} className="img-fluid" alt="" /></a>
                            </Col>
                        </Row>
                    </Col>
                }
                {this.props.type === 'sidebar' &&
                    <Col xs={12} md={12}>
                        {this.props.title &&
                            <Row>
                                <Col xs={12} className="mb-3">
                                    <h5 className="font-semi-bold color-dark mb-3">{this.props.title}</h5>
                                </Col>
                            </Row>
                        }
                        <Row>
                            {posts.map((post, index) => (
                                <Col xs={6} key={index} className="mb-4">
                                    <a href={post.permalink} target="_blank" rel="noreferrer">
                                        <Card className="card-post-instagram">
                                            <img src={post.media_url} className="img-fluid" alt="" />
                                        </Card>
                                    </a>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                }
            </>
        );
    }
}
