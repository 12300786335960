import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../../../../components/Layout";

export default class PoliticaPrivacidade2020 extends Component {

    componentDidMount() {
        document.title = "Site Blindado - Política de Privacidade 2020";
    }

    render() {
        return (
            <Layout>
                <div className="padding-container padding-top-mobile bg-dark-green">
                    <Container>
                        <Row className="h-100 align-items-center">
                            <Col xs={12} md={8}>
                                <h1 className="font-black mb-4">Política de Privacidade - 17 de Dezembro de 2020</h1>
                            </Col>
                            <Col xs={12} md={4}>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <Col xs={12} md={12}>
                                <p>O Site Blindado tem o compromisso com a transparência,
                                    a privacidade e a segurança  dos  dados  dos  usuários
                                    do  site  durante  todo  o  processo  de navegação.
                                    Para  que  entendam  melhor  quais  informações  coletamos
                                    e como as utilizamos, armazenamos ou excluímos, detalhamos
                                    a seguir nossa Política de Privacidade.
                                </p>

                                <p>Para que a Política de Privacidade seja bem compreendida, é fundamental esclarecer alguns conceitos importantes:</p>

                                <p><strong>- Usuário – </strong> toda pessoa física que navega no site;</p>
                                <p><strong>- Cliente – </strong> toda  pessoa  física  ou  jurídica  que  já  adquiriu  serviços  do  Site Blindado;</p>
                                <p><strong>- Titular dados – </strong> qualquer  pessoa  física  que  tenha  seus  dados  pessoais tratados pelo Site Blindado;</p>
                                <p><strong>-  Dados  pessoais – </strong>  qualquer  informação  relacionada  a  uma  pessoa  que  a identifique   ou   que,   usada
                                    em   combinação   com   outras   informações tratadas, identifique um indivíduo. Ainda, qualquer informação por meio da qual
                                    seja possível identificar uma pessoa ou entrar em contato com ela.
                                </p>
                                <p><strong>-  Tratamento  de  dados  pessoais  – </strong> considera-se  tratamento  de  dado pessoal  a  coleta,  produção,  recepção,  classificação,
                                    utilização,  acesso, reprodução,   transmissão,   distribuição,   processamento,   arquivamento, armazenamento,   eliminação,   avaliação
                                    ou   controle   da   informação, comunicação,  transferência,  difusão  ou  extração  de  dados  de  pessoas físicas.
                                </p>
                                <p><strong>-  Finalidade  – </strong> o  que  queremos  alcançar  com  o  tratamento  de  dados pessoais.</p>
                                <p><strong>- Necessidade – </strong>o tratamento de dados pessoais deve se limitar ao <u>mínimo necessário</u>  para  o  propósito  almejado.  Ou  seja,  deve
                                    ser  pertinente, proporcional e não excessivo.
                                </p>
                                <p><strong>-  Consentimento  – </strong> autorização  clara  e  objetiva  que  o  titular  dá  para tratamento de seus dados pessoais com finalidade previamente
                                    estipulada. Após  dar  o  <b>consentimento</b>,  <u>você  pode revogá-lo  a  qualquer  momento</u>.  A revogação não cancela os processamentos realizados previamente.
                                </p>

                                <br />
                                <p>-  O  Site  Blindado é  marca  integrante  da  <strong>B2W  Digital</strong>,  que  faz  parte  do <strong>Universo Americanas</strong>, composto pelas empresas listadas abaixo:</p>

                                <p className="mb-0">Lojas Americanas S.A. – 33.014.556/0001-96</p>
                                <p className="mb-0">B2W – Companhia Digital – 00.776.574/0006-60</p>
                                <p className="mb-0">BIT Services Inovação e Tecnologia Ltda. – 03.789.968/0001-37</p>
                                <p className="mb-0">Digital Finance Promotora Ltda. – 19.179.007/0001-40</p>
                                <p className="mb-0">Direct Express Logística Integrada S.A. – 05.886.614/0001-36</p>
                                <p className="mb-0">ST Importações Ltda. – 02.867.220/0001-42</p>
                                <p className="mb-0">Submarino Finance Promotora de Crédito Ltda. – 07.897.468/0001-70</p>
                                <p className="mb-0">Ame Digital Brasil Ltda. – 32.778.350/0001-70</p>
                                <p className="mb-0">Ecologística Entregas Sustentáveis Ltda. – 22.236.389/0001-01</p>
                                <p className="mb-0">Courrieros Transportes Ltda. – 29.386.039/0001-70</p>
                                <p className="mb-0">Supernow Portal e Serviços de Internet Ltda. – 23.559.907/0001-90</p>
                                <p className="mb-0">Ecolivery Courrieros Ltda – 16.890.506/0001-53</p>

                                <br />

                                <ul style={{ listStyle: 'decimal', marginTop: 40 }}>
                                    <li><strong>A quem essa Política de Privacidade se aplica?</strong></li>
                                    <p>Aplica-se  a  todos  os  usuários  e  clientes  do  Site  Blindado,  que  de  alguma forma tenham os dados pessoais tratados por nós.</p>
                                    <br />

                                    <li><strong>Que tipo de informações pessoais coletamos e utilizamos?</strong></li>
                                    <p>O Site Blindado coleta e armazena os seguintes tipos de informações:</p>
                                    <p>
                                        <strong>Informações que você nos fornece: </strong>
                                        O Site Blindado coleta informações do usuário quando você preenche um formulário para adquirir serviços e nos fornece o nome completo,
                                        e-mail e telefone de contato de um responsável ou nos envia uma solicitação de suporte pelo site.
                                    </p>
                                    <p>
                                        <strong>Informações coletadas automaticamente pelo Site Blindado: </strong>
                                        coletamos e armazenamos  determinadas  informações  sempre  que  o  Cliente  interage conosco. Por exemplo, utilizamos cookies
                                        e obtemos informações quando seu navegador acessa o nosso site.
                                    </p>
                                    <br />

                                    <li><strong>Por que solicitamos seus dados pessoais?</strong></li>
                                    <p>Os dados são necessários para:</p>

                                    <ul style={{ listStyle: 'circle', marginBottom: 20 }}>
                                        <li>atender as solicitações de aquisição de produtos e oferecer a melhor solução aos clientes;</li>
                                        <li>otimizar sua interação conosco;</li>
                                        <li>garantir a segurança do site e dos dados que processa;</li>
                                        <li>informar ao Cliente sobre as ofertas e divulgar os nossos serviços.</li>
                                    </ul>

                                    <p>O fornecimento dos dados permite:</p>
                                    <ul style={{ listStyle: 'circle', marginBottom: 20 }}>
                                        <li>prestar serviços adequados às necessidades do Cliente;</li>
                                        <li>melhorar  a  experiência  de  navegação  dos  usuários  no  site  do  Site Blindado;</li>
                                        <li>manter  o  Cliente  informado  sobre  os  produtos  e  serviços  que oferecemos;</li>
                                        <li>melhorar nosso website, produtos e serviços.</li>
                                    </ul>

                                    <p>
                                        O  Site  Blindado  utiliza  bases  legais,  que  podem  variar  de  acordo  com  a finalidade da coleta, para tratar os dados pessoais dos clientes.
                                        O prazo de armazenamento  pode  mudar  conforme  a  base  legal  aplicável  a  cada situação e a finalidade.
                                    </p>
                                    <br />

                                    <ol style={{ paddingLeft: 0, marginBottom: 20 }}>3.1 Serviços de marketing</ol>
                                    <p style={{ marginBottom: 20, marginLeft: 25 }}>Os  serviços  de  marketing  são  oferecidos  por  meio  de  comunicações gratuitas  sobre  produtos  e  serviços  dirigidas  ao  Cliente.  Esse  serviço
                                        abrange  o  envio  de  e-mails.  Vale  ressaltar  que  o  Cliente  pode  cancelar  o serviço,  a  qualquer  momento,  clicando  no  link  de  descadastro
                                        enviado junto com o e-mail do marketing.
                                    </p>
                                    <br />

                                    <li><strong>Com quem compartilhamos seus dados?</strong></li>
                                    <p>
                                        As informações coletadas somente serão compartilhadas quando forem necessárias:
                                        (i) proteção em caso de conflito;
                                        (ii) mediante decisão judicial ou requisição de autoridade competente;
                                        (iii) com empresas provedoras de infraestrutura tecnológica e operacional, como provedoras de serviço de armazenamento de informações.
                                    </p>
                                    <br />

                                    <li><strong>Transferência Internacional de dados</strong></li>
                                    <p>Como a internet é um ambiente global, determinados serviços oferecidos pelo  Site  Blindado  podem  demandar  a  transferência  dos  seus  dados  para outros países.</p>
                                    <p>
                                        Nesses  casos,  os  dados  são  tratados  de  acordo  com  a  LGPD  (Lei  Geral  de Proteção  de  Dados)  e  demais  legislações  de  proteção.  Tomamos  medidas de segurança
                                        de acordo com nossas políticas e adotamos cláusulas padrão nos contratos com fornecedores e prestadores de serviço.
                                    </p>
                                    <p>
                                        Ao navegar em nosso site ou se comunicar conosco, você concorda com o tratamento de suas informações, inclusive a transferência internacional de dados, quando necessário. Adotamos
                                        medidas para garantir que quaisquer informações   coletadas   sejam   tratadas   com   segurança,   conforme   os padrões de proteção de dados e de acordo com esta Política de Privacidade.
                                    </p>
                                    <br />

                                    <li><strong>Por quanto tempo armazenamos informações pessoais?</strong></li>
                                    <p>Armazenamos as informações dos Clientes de acordo com as normas de prescrição do Direito brasileiro. </p>
                                    <br />

                                    <li><strong>Quais são os direitos do titular de dados?</strong></li>
                                    <p>O  titular  dos  dados  pessoais  tem  o  direito  de  obter  do  Site  Blindado,  a qualquer  momento,  mediante  requisição  formal,  informações  referentes aos seus dados.</p>
                                    <p>
                                        O Site Blindado terá 15 dias para responder às solicitações dos titulares. Os pedidos  serão  analisados  conforme  previsto  em  legislação  vigente  e,  por questões legais,
                                        algumas solicitações podem não ser atendidas.
                                    </p>

                                    <p>Os  titulares  dos  dados,  segundo  o  texto  da  LGPD,  podem  exercer  os  seus direitos por meio de:</p>
                                    <ul style={{ listStyle: 'upper-roman', marginBottom: 20 }}>
                                        <li> confirmação da existência de tratamento;</li>
                                        <li>acesso aos dados;</li>
                                        <li>correção de dados incompletos, inexatos ou desatualizados;</li>
                                        <li>anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
                                        <li>
                                            portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da
                                            autoridade nacional, observados os segredos comercial e industrial;
                                        </li>
                                        <li>eliminação dos dados pessoais tratados com o consentimento do titular;</li>
                                        <li>informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</li>
                                        <li>informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
                                        <li>revogação do consentimento.</li>
                                    </ul>
                                    <br />

                                    <li><strong>Como exercer os seus direitos?</strong></li>
                                    <p>Você   pode   exercer   seus   direitos   entrando   em   contato   pelo   e-mail suporte@siteblindado.com.br.</p>
                                    <p>Se  não  quiser  receber  nossas  comunicações  de  marketing,  também  pode cancelar a assinatura clicando no link de descadastro enviado junto com o e-mail.</p>
                                    <br />

                                    <li><strong>Cookies e tecnologias semelhantes</strong></li>
                                    <p>
                                        Cookies  são  pequenos  arquivos  de  dados  que  são  colocados  no  seu computador  ou  em  outros  dispositivos  (como  'smartphones'  ou  'tablets')
                                        enquanto você navega no site.
                                    </p>
                                    <p>
                                        Utilizamos  cookies,  pixels  e  outras  tecnologias  (coletivamente,  "cookies") para  reconhecer  seu  navegador  ou  dispositivo,  aprender  mais  sobre
                                        seus interesses, fornecer recursos e serviços essenciais e também para:
                                    </p>
                                    <ul style={{ listStyle: 'disc', marginBottom: 20 }}>
                                        <li>realização  de  pesquisas  e  diagnósticos  para  melhorar  o  conteúdo, produtos e serviços;</li>
                                        <li>impedir atividades fraudulentas;</li>
                                        <li>melhorar a segurança.</li>
                                    </ul>

                                    <p>Se  você  bloquear  ou  rejeitar  nossos  cookies,  algumas  funcionalidades  do site podem não funcionar corretamente.</p>

                                    <p>
                                        Clique  em  'Ajuda',  nas  configurações  do  seu  navegador,  para  saber  como impedi-lo  de  aceitar  cookies  e  para  ser  notificado  quando  receber  novos;
                                        para  ver  quando  eles  expiram  e  para  desativá-los.  Nos  links  abaixo  você encontra mais detalhes sobre como desativar os cookies dos navegadores mais populares:
                                    </p>

                                    <ul style={{ listStyle: 'disc', marginBottom: 20 }}>
                                        <li><a className="font-bold color-primary text-decoration-none" href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;oco=1&amp;hl=pt-BR" target="_blank" rel="noreferrer">Google Chrome</a></li>
                                        <li><a className="font-bold color-primary text-decoration-none" href="https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s" target="_blank" rel="noreferrer">Mozilla Firefox</a></li>
                                        <li><a className="font-bold color-primary text-decoration-none" href="https://support.apple.com/pt-br/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">Safari</a></li>
                                        <li><a className="font-bold color-primary text-decoration-none" href="https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer">Internet Explorer</a></li>
                                        <li><a className="font-bold color-primary text-decoration-none" href="https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies" target="_blank" rel="noreferrer">Microsoft Edge</a></li>
                                        <li><a className="font-bold color-primary text-decoration-none" href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank" rel="noreferrer">Opera</a></li>
                                    </ul>
                                    <br />

                                    <li><strong>Como você pode manter suas informações pessoais seguras?</strong></li>
                                    <p>O Site Blindado utiliza os melhores protocolos de segurança para preservar a privacidade dos dados dos Clientes, mas também recomenda medidas de proteção individual.</p>
                                    <p>Não entramos em contato por WhatsApp, SMS ou e-mail solicitando dados pessoais. Em nenhuma hipótese eles devem ser fornecidos, pois pode ser uma tentativa de uso indevido.</p>
                                    <br />

                                    <li><strong>Outras informações importantes sobre proteção de dados</strong></li>
                                    <p>
                                        Para  garantir  que  as  regras  estão  claras  e  precisas,  podemos  alterar  essa política a qualquer momento, publicando a Política de Privacidade revisada
                                        neste site e indicando a data de efetivação do documento.
                                    </p>
                                    <br />

                                    <li>Como entrar em contato com o encarregado da proteção de dados do Site Blindado? </li>
                                    <p>
                                        O  encarregado  da  proteção  de  dados  é  o  responsável  escolhido  pelo  Site Blindado  para  atuar  como  canal  de  comunicação  entre  o  controlador,
                                        os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
                                    </p>
                                    <p>Qualquer   dúvida   sobre   tratamento   de   dados   pessoais   poderá   ser encaminhada para o e-mail suporte@siteblindado.com.br.</p>
                                    <br />

                                    <li><strong>Legislação e foro</strong></li>
                                    <p>
                                        Esta política será regida, interpretada e executada de acordo com as Leis da República   Federativa   do   Brasil,   especialmente   a   Lei   nº   13.709/2018,
                                        independentemente   das   Leis   de   outros   estados   ou   países,   sendo competente  o  foro  de  domicílio  do  Cliente  para  dirimir
                                        qualquer  dúvida decorrente deste documento.
                                    </p>
                                    <br />
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}