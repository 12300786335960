import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Layout from "../../components/Layout";
import FormSB from "../../components/Form";
import ArrowButton from "../../components/ArrowButton";
import InstagramPosts from "../../components/InstagramPosts";
import Slider from 'react-slick';

// images
import About from "../../assets/img/about/about.png";
import Sub from "../../assets/img/submarino.png";
import Renner from "../../assets/img/renner.png";
import Shoptime from "../../assets/img/shoptime.png";
import Ultrafarma from "../../assets/img/ultrafarma.png";
import Kabum from "../../assets/img/kabum.png";
import Domina from "../../assets/img/domina.png";
import Polishop from "../../assets/img/polishop.png";
import Itau from "../../assets/img/home/itau.png";
import Localiza from "../../assets/img/home/localiza.png";
import Americanas from "../../assets/img/home/americanas.png";
import News1 from "../../assets/img/about/ti_inside.png";
import News2 from "../../assets/img/about/agencia_fg.png";
import News3 from "../../assets/img/about/canaltech.png";
import News4 from "../../assets/img/about/jornal_contabil.png";


export default class Sobre extends Component {
    state = {
        data: [],
        description: 'A Site Blindado é uma empresa de segurança de dados que oferece proteção contra falhas, ataques, fraudes e invasões a e-commerces e sites corporativos. Conheça agora.'
    }

    componentDidMount() {
        document.title = "Sobre a Site Blindado: conheça o que nós fazemos";
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
            metaTag.setAttribute('content', this.state.description ? this.state.description : '');
        };
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render() {
        const settings = {
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            infinite: true,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 425,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };

        const settingsClients = {
            arrows: false,
            dots: false,
            slidesToShow: 6,
            autoplay: true,
            autoplaySpeed: 2000,
            slidesToScroll: 1,
            infinite: true,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1440,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
            ]
        };

        const settingsTestimonial = {
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        };

        return (
            <Layout>
                <div className="section-height-100-center bg-dark-green">
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} md={6}>
                                <h3 className="font-black mb-5">Nós nascemos com a internet e <span className="color-primary">crescemos juntos</span> com ela!</h3>
                                <h6 className="font-light">Já são <span className="font-semi-bold">quase 20 anos</span> desenvolvendo soluções focadas na construção de uma rede mais segura para marcas e seus respectivos clientes. Nossas soluções <span className="font-semi-bold">protegem de verdade</span> as empresas e contribuem para reputações duradouras.</h6>
                            </Col>
                            <Col xs={12} md={6} className="text-right d-none d-lg-block">
                                <img src={About} className="img-fluid" alt="" />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row className="mb-5">
                            <Col xs={12}>
                                <h3 className="color-dark">Mais de 5.000 <span className="color-primary font-semi-bold">sites protegidos</span></h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Row>
                                    <Slider {...settingsClients} className="p-0">
                                        <Card className="clients-about-us">
                                            <img src={Sub} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Renner} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Shoptime} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Ultrafarma} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Kabum} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Polishop} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Itau} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Localiza} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Americanas} className="img-fluid" alt="" />
                                        </Card>
                                        <Card className="clients-about-us">
                                            <img src={Domina} className="img-fluid" alt="" />
                                        </Card>
                                    </Slider>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <Col xs={12} md={12} className="bg-about-woman">
                                <Row>
                                    <Col xs={12} md={4}>
                                        <h1 className="title-products font-black mb-4">“</h1>
                                        <Slider {...settingsTestimonial} className="slick-testimonial">
                                            <div>
                                                <h6 className="font-light">O comprometimento do time da Site Blindado nos proporciona tranquilidade e eficiência. Além de fornecer um serviço com qualidade, capaz de garantir a segurança do nosso site, a parceria aumenta a confiabilidade dos nossos clientes e parceiros.</h6>
                                            </div>
                                            <div>
                                                <h6 className="font-light">A mesma tranquilidade que nossos clientes têm quando veem o selo da Site Blindado em nosso e-commerce, é compartilhada por nossa equipe quando se trata do serviço que ela presta.</h6>
                                            </div>
                                            <div>
                                                <h6 className="font-light">A Site Blindado atende todas as nossas expectativas. Possui uma equipe de suporte ágil, qualificada e comprometida, garantindo para nós e nossos clientes um serviço eficiente e seguro.</h6>
                                            </div>
                                        </Slider>
                                    </Col>
                                </Row>
                                <Card className="send-testimonial d-none d-lg-block">
                                    <ArrowButton link="mailto:relacionamento@siteblindado.com" type="green" side="center" text="Envie seu depoimento" />
                                </Card>
                            </Col>
                        </Row>
                        <Row className="padding-row">
                            <InstagramPosts type='container' title={<>Explore o universo <span className="font-semi-bold">Site Blindado</span></>} />
                        </Row>
                        <Row className="padding-row" id="form">
                            <Col xs={12} md={12}>
                                <FormSB color="white" type="form-client" source="Página de Sobre" parentCallback={this.callbackFunction} title="Quero Ser Blindado" description={<>Preencha o formulário a seguir para que a nossa equipe de especialistas possa entrar em contato e oferecer a <span className="font-semi-bold color-primary">melhor solução de segurança online</span> que atenda às suas necessidades.</>} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="bg-color-secondary">
                    <Container className="pb-4">
                        <Row>
                            <Col xs={12}>
                                <h3 className="text-center mt-5 mb-4">Site Blindado <span className="font-semi-bold">na Mídia</span></h3>
                                <Slider {...settings} className="slick-news">
                                    <Row>
                                        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
                                            <a href="https://tiinside.com.br/04/04/2016/site-blindado-traz-scan-ssl-para-segmento-de-e-commerce/" target={"_blank"} rel="noreferrer"><img src={News1} className="img-fluid" alt="" /></a>
                                            <ArrowButton link="https://tiinside.com.br/04/04/2016/site-blindado-traz-scan-ssl-para-segmento-de-e-commerce/" newTab="true" type="white" side="left" text="Acessar" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
                                            <a href="https://agenciafg.com.br/blog/selos-de-seguranca/" target={"_blank"} rel="noreferrer"><img src={News2} styles="width: 160px" className="img-fluid" alt="" /></a>
                                            <ArrowButton link="https://agenciafg.com.br/blog/selos-de-seguranca/" newTab="true" type="white" side="left" text="Acessar" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
                                            <a href="https://canaltech.com.br/seguranca/seguranca-corporativa-como-fazer-43301/" target={"_blank"} rel="noreferrer"><img src={News3} className="img-fluid" alt="" /></a>
                                            <ArrowButton link="https://canaltech.com.br/seguranca/seguranca-corporativa-como-fazer-43301/" newTab="true" type="white" side="left" text="Acessar" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} className="d-flex flex-column align-items-center justify-content-center">
                                            <a href="https://www.jornalcontabil.com.br/torne-seu-site-mais-seguro-para-o-cliente/" target={"_blank"} rel="noreferrer"><img src={News4} className="img-fluid" alt="" /></a>
                                            <ArrowButton link="https://www.jornalcontabil.com.br/torne-seu-site-mais-seguro-para-o-cliente/" newTab="true" type="white" side="left" text="Acessar" />
                                        </Col>
                                    </Row>
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}
