import React, { Component } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Layout from "../../../components/Layout";
import FormSB from "../../../components/Form";
import BlogNews from "../../../components/BlogNews";
import SeloBlindado from "../../../assets/img/selo-blindado.png";
import SeloSSL from "../../../assets/img/selo-ssl-2.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
export default class Denuncie extends Component {
    state = {
        data: [],
    }

    componentDidMount () {
        document.title = "Site Blindado - Denuncie";
    }

    callbackFunction = (data) => {
        this.setState({ data })
    };

    render () {
        const { data } = this.state;

        return (
            <Layout>
                <div className={`padding-container bg-dark-green ${(data.status === 'error' ? 'bg-dark-red' : '') || (data.status === 'ok' ? 'bg-dark-blue' : '')}`}>
                    <Container>
                        <Row className="padding-top-mobile">
                            <Col xs={12} md={12}>
                                <h2 className="font-black text-center">Encontrou um site utilizando o Selo Falso da Site Blindado?</h2>
                                <h6 className="text-center">Nossos selos de segurança originais são estes:</h6>
                                <Row className="mb-4">
                                    <Col xs={12} md={12} className="text-center">
                                        <img src={SeloBlindado} className="img-fluid selo-img me-5" alt="" />
                                        <img src={SeloSSL} className="img-fluid" alt="" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Card bg="danger">
                                        <Card.Body>
                                            <Card.Title style={{ fontWeight: 'bold' }}>
                                                <span style={{ marginRight: '5px' }}>
                                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                                </span>
                                                Atenção
                                            </Card.Title>
                                            <Card.Text className="text-justify" style={{ color: "#ffffff" }}>
                                                Prezados Clientes e Consumidores, Em atenção ao nosso compromisso com os mais altos padrões de segurança e transparência, informamos que foram identificados usos indevidos de nossa marca na chave Pix de transações de venda de aparelhos celulares por terceiros. Reiteramos que a Site Blindado é uma empresa especializada em serviços de cibersegurança para e-commerces e sites institucionais e, como tal, não comercializa quaisquer produtos físicos. A prática identificada é falsa e ilegal, e nós já estamos avaliando as medidas cabíveis para coibir este tipo de ação, que pode gerar prejuízo aos consumidores. Recomendamos cuidado redobrado no momento da compra de qualquer produto. Em caso de dúvida, entre em contato com nossos canais de atendimento. Time Site Blindado
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Row>
                                <br />
                                <h6 className="text-center">Preencha o formulário abaixo com o endereço do site que você encontrou a exibição indevida da marca Site Blindado.</h6>
                                <FormSB type='form-report' parentCallback={this.callbackFunction} />
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="padding-container">
                    <Container>
                        <Row>
                            <BlogNews />
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}
