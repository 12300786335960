import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

export default function ContentfulPagination({ currentPage, totalPages, visiblePages, onPageChange }) {
  const handlePageChange = (page) => {
    if (page !== currentPage) {
      onPageChange(setSkip(page), page);
    }
  };

  const setSkip = (skipPage) => {
    const skip = skipPage - 1;
    return skip * 4
  }

  const getPaginationItems = () => {
    const items = [];
    const maxVisiblePages = visiblePages;

    if (totalPages <= maxVisiblePages) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }
    } else {
      let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
      let endPage = startPage + maxVisiblePages - 1;

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = endPage - maxVisiblePages + 1;
      }

      items.push(
        <Pagination.Item key={1} active={1 === currentPage} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );

      if (startPage >= 2) {
        items.push(<Pagination.Ellipsis key="ellipsis-start" />);
      }

      for (let number = startPage + 1; number < endPage; number++) {
        items.push(
          <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
            {number}
          </Pagination.Item>
        );
      }

      if (endPage <= totalPages - 1) {
        items.push(<Pagination.Ellipsis key="ellipsis-end" />);
      }

      items.push(
        <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Pagination>
      <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
      {getPaginationItems()}
      <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    </Pagination>
  );
};
