export const linkRenderer = (link) => {
    return (
        <a href={link.href} className="color-blue text-decoration-none font-semi-bold" target="_blank" rel="noreferrer">
        {link.children}
        </a>
    );
};

export function formatBody(body, maxLength = 80) {
    let formattedBody = body;

    formattedBody = formattedBody.replace(/\[(.*?)\]\(.*?\)/g, "$1");
    formattedBody = formattedBody.replace(/!\[(.*?)\]\(.*?\)/g, "$1");
    formattedBody = formattedBody.replace(/\*\*(.*?)\*\*/g, "$1");
    formattedBody = formattedBody.replace(/__(.*?)__/g, "$1");
    formattedBody = formattedBody.replace(/\*(.*?)\*/g, "$1");
    formattedBody = formattedBody.replace(/_(.*?)_/g, "$1");
    formattedBody = formattedBody.replace(/~~(.*?)~~/g, "$1");
    formattedBody = formattedBody.replace(/`(.*?)`/g, "$1");
    formattedBody = formattedBody.replace(/(<([^>]+)>)/gi, '');
    if (formattedBody.length > maxLength) {
        const lastSpaceIndex = formattedBody.lastIndexOf(' ', maxLength);
        formattedBody = `${formattedBody.substring(0, lastSpaceIndex)}...`;
    }

    return formattedBody;
}
