import axios from 'axios';
import { CONTENTFUL_ASSETS_URL, CONTENTFUL_BASE_URL } from '../constants/contentful';

const CONTENTFUL_TOKEN = process.env.REACT_APP_CONTENTFUL_TOKEN;

export async function getRichContentData(skip) {
  const { data } = await axios.get(`${CONTENTFUL_BASE_URL}`, {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'material-rico',
      limit: 4,
      skip: skip,
      order: '-fields.date',
      include: 1,
    },
  });
  return data;
}

export async function getRichContentSearchedCategory(skip, categoria) {
  const { data } = await axios.get(`${CONTENTFUL_BASE_URL}`, {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'material-rico',
      limit: 4,
      skip: skip,
      order: '-fields.date',
      include: 1,
      [`fields.categoria[match]`]: categoria,
    },
  });
  return data;
}

export async function getCategoriesData(skip) {
  const { data } = await axios.get(`${CONTENTFUL_BASE_URL}`, {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'material-rico',
      limit: 4,
      skip: skip,
      order: '-fields.date',
      include: 1,
    },
  });
  return data;
}

export async function getRichContentAsset(assetId) {
  const { data } = await axios.get(`${CONTENTFUL_ASSETS_URL}${assetId}`, {
    headers: { Authorization: `Bearer ${CONTENTFUL_TOKEN}` },
  });
  return data;
}
